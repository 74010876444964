import React from "react"
import { func, bool } from "prop-types"
import { connect } from "react-redux"
import { AppBar, Button, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material/"
import { getOnboardingStep } from "redux/onboarding/selectors"

const indexForQuestionBeforeChooseCaregiver = 7
const indexForFirstQuestion = 0

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  greenButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  backButtonText: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "10px",
    },
  },
  nextButtonText: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "6px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10px",
    },
  },
}))

const FormStepper = ({
  prevClick,
  nextClick,
  step,
  hideNext = false,
  isLoadingNextStep,
  disabled,
}) => {
  const classes = useStyles()
  const isQuestionBeforeChooseCaregiver =
    indexForQuestionBeforeChooseCaregiver === step

  return (
    <AppBar position="relative" className={classes.root}>
      <Button
        data-testid="form_stepper_prev"
        onClick={prevClick}
        disabled={indexForFirstQuestion === step}
        color="default"
      >
        <KeyboardArrowLeft />
        <Typography className={classes.backButtonText}>Tillbaka</Typography>
      </Button>
      {!hideNext && (
        <Button
          className={
            isQuestionBeforeChooseCaregiver ? classes.greenButton : null
          }
          data-testid="form_stepper_next"
          type={isQuestionBeforeChooseCaregiver ? "button" : "submit"}
          disabled={isLoadingNextStep || disabled}
          color="default"
          onClick={isQuestionBeforeChooseCaregiver ? nextClick : null}
        >
          <Typography className={classes.nextButtonText}>
            {isQuestionBeforeChooseCaregiver ? "Till matchningen" : "Nästa"}
          </Typography>
          <KeyboardArrowRight />
        </Button>
      )}
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  const step = getOnboardingStep(state)

  return { step }
}

FormStepper.propTypes = {
  prevClick: func.isRequired,
  hideNext: bool,
}

export default connect(mapStateToProps)(FormStepper)
