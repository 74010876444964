import { isBefore, parseISO } from "date-fns"
import isEmpty from "lodash.isempty"

import store from "redux/store"
import getUserAge from "utils/getUserAge"
import isFreeCardValid from "utils/isFreeCardValid"
import isPayingCustomer from "utils/isPayingCustomer"
import isActiveSession from "utils/isActiveSession"
import { setUserChatSession } from "redux/chat"
import { setUserCustomerStatusInactive } from "redux/user"
import TagManager from "react-gtm-module"

/**
 * This method will create session, change payment status or neither depending on users age, freeCard, or payment status
 *
 * @param {boolean} [shouldCreateSession=true]
 * @returns {Promise<boolean>}
 */

export default async function sessionHandler(shouldCreateSession = true) {
  const { getState, dispatch } = store
  const { chat, caregiver, user } = getState() || {}
  const {
    socialSecurity,
    freeCard,
    customerStatus,
    latestPayment,
    userId,
    isSormland,
  } = user || {}
  const { userId: caregiverId } = caregiver?.data || {}
  const { session } = chat || {}

  const userAge = getUserAge(socialSecurity)
  const isFreeAge = userAge < 20 || userAge >= 85
  const hasValidFreeCard = isFreeCardValid(freeCard?.expirationDate)
  const hasValidPayment = isPayingCustomer(customerStatus)

  // TODO: maybe wrap all help functions and catch if they throw
  // they should throw if provided with incorrect data
  try {
    if (!isEmpty(session)) {
      if (isActiveSession(session)) {
        return Promise.resolve(true)
      }

      if (
        hasValidPayment &&
        isBefore(parseISO(latestPayment), parseISO(session.endedAt))
      ) {
        await dispatch(setUserCustomerStatusInactive()).unwrap()
        return Promise.resolve(false)
      }
    }

    if (isFreeAge || hasValidFreeCard || hasValidPayment) {
      if (shouldCreateSession) {
        const patientTypes = {
          1: isFreeAge,
          2: hasValidFreeCard,
          3: hasValidPayment,
        }
        const patientType = Object.keys(patientTypes).find(
          (key) => patientTypes[key]
        )
        const newCustomer = !session

        await dispatch(
          setUserChatSession({ userId, caregiverId, patientType })
        ).unwrap()
        TagManager.dataLayer({
          dataLayer: {
            userId,
            event: "sessionCreated",
            patientType,
            isSormland,
            newCustomer,
          },
        })
      }
      return Promise.resolve(true)
    }

    return Promise.resolve(false)
  } catch (e) {
    return Promise.reject(e)
  }
}
