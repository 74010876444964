import React from "react"
import { connect } from "react-redux"
import { Box, Typography, CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import CaregiverImage from "pages/chat/components/CaregiverImage"
import config from "config"

const SIDEBAR_WIDTH = 250
const COMPANY_NAME = config.company_name
const CURRENT_YEAR = new Date().getFullYear()

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    flexGrow: 1,
    maxWidth: SIDEBAR_WIDTH,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.lighter_gray,
    borderLeft: `0.5px solid ${theme.palette.gray}`,
    borderRight: `0.5px solid ${theme.palette.gray}`,
    padding: theme.spacing(2),
  },
  title: {
    alignSelf: "flex-start",
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  textSpacing: {
    marginTop: theme.spacing(2),
  },
  text: {
    color: theme.palette.dark_gray,
    textDecoration: "none",
  },
  name: {
    color: theme.palette.black,
    fontWeight: "bold",
  },
  linkContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  link: {
    cursor: "pointer",
  },
}))

const Sidebar = ({ caregiver }) => {
  const classes = useStyles()
  const { loading, error, data } = caregiver

  // eslint-disable-next-line react/no-unstable-nested-components
  const CaregiverInfo = () => {
    if (loading) {
      return <CircularProgress size={50} color="primary" />
    }

    return (
      <>
        <CaregiverImage />
        <Typography
          className={`${classes.textSpacing} ${classes.name}`}
        >{`${firstName} ${lastName}`}</Typography>
        <Typography className={classes.text}>(Leg. Psykolog)</Typography>
      </>
    )
  }

  if (error) {
    return null
  }

  const { firstName = "", lastName = "" } = data || {}

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title} color="primary">
        Mitt chattrum
      </Typography>
      <CaregiverInfo />
      <Box className={classes.linkContainer}>
        <Typography
          variant="caption"
          className={`${classes.textSpacing} ${classes.text}`}
        >
          &#169; {COMPANY_NAME} {CURRENT_YEAR}
        </Typography>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { caregiver } = state

  return { caregiver }
}

export default connect(mapStateToProps)(Sidebar)
