import Cookie from "js-cookie"
import { setInitialLoading, setToken } from "redux/common"
import { getUserProfile } from "redux/user"
import Api from "api"

export const getUserFromCookie = async (dispatch) => {
  const patient = Cookie.get("patient")
  const { id, token } = patient ? JSON.parse(patient) : {}
  Api.defaults.headers.common.Authorization = `Bearer ${token}`

  if (id) {
    await dispatch(getUserProfile(id)).then(() => {
      dispatch(setToken(token))
    })
  }
  dispatch(setInitialLoading(false))
}
