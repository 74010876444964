import Joi from "@hapi/joi"

const singleAnswerSchema = (data, type) => {
  switch (type) {
    case "string":
      return Joi.object({
        [Object.keys(data)[0]]: Joi.string().required(),
      }).validate(data, {
        abortEarly: false,
      })
    case "boolean":
      return Joi.object({
        [Object.keys(data)[0]]: Joi.string().valid("true", "false").required(),
      }).validate(data, {
        abortEarly: false,
      })
    default:
      throw new Error("Unknown singleAnswer type")
  }
}

export default singleAnswerSchema
