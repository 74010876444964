/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  Box,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Button,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Backdrop,
  Container,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import sessionHandler from "utils/sessionHandler"
import config from "config"
import { Logo } from "assets"
import { selectUserData } from "redux/user"
import { Videocam as VideocamIcon } from "@mui/icons-material"
import format from "date-fns/format"
import sv from "date-fns/locale/sv"
import DefaultAvatar from "./DefaultAvatar"
import BurgerMenuIcon from "./BurgerMenuIcon"
import VideoMeetingModal from "./VideoMeetingModal"

const COMPANY_NAME = config.company_name
const DRAWER_WIDTH = "80%"
const CURRENT_YEAR = new Date().getFullYear()

const useStyles = makeStyles((theme) => ({
  root: (height) => ({
    position: "relative",
    height,
    display: "flex",
    flexDirection: "column",
  }),
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    height: 64,
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    top: "64px",
  },
  displayName: {
    color: "#000",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  videoButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.89rem",
    color: theme.palette.primary.main,
    "&:hover, &:active, &:focus": {
      background: "none",
      textDecoration: "underline",
    },
    [theme.breakpoints.up("md")]: {
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(4),
        color: "inherit",
      },
      "& .MuiListItemText-root > *": {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
  },
}))

const toggleDrawer = (isOpen, setOpen) => (event) => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return
  }

  setOpen(isOpen)
}

const ListItemLink = (props) => {
  const {
    icon,
    primary,
    secondary,
    videoMeetingFormattedStart,
    onVideoMeetingLinkClick,
    to,
    className,
    sameWindow,
  } = props
  const navigate = useNavigate()

  return (
    <ListItem
      button
      onClick={() =>
        videoMeetingFormattedStart
          ? onVideoMeetingLinkClick()
          : sameWindow
          ? navigate(to)
          : window.open(to, "_blank")
      }
      className={className}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  )
}

const RenderDrawer = ({
  isOpen,
  setOpen,
  isActiveSession,
  videoMeetingFormattedStart,
  onVideoMeetingLinkClick,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Drawer variant="persistent" open={isOpen} {...rest}>
      <List>
        {isActiveSession && (
          <ListItemLink
            primary={
              videoMeetingFormattedStart
                ? "Kommande videomöte:"
                : "Boka videomöte"
            }
            secondary={videoMeetingFormattedStart}
            icon={<VideocamIcon />}
            className={classes.videoButton}
            sameWindow
            videoMeetingFormattedStart={videoMeetingFormattedStart}
            onVideoMeetingLinkClick={onVideoMeetingLinkClick}
            to={videoMeetingFormattedStart ? null : "/video"}
          />
        )}
        <ListItemLink to={config.about_us} primary="Om oss" />
        <ListItemLink to={config.faq} primary="Vanliga frågor" />
        <ListItemLink to={config.support} primary="Support" />
        <Divider />
        <ListItem>
          <Typography variant="caption">
            &#169; {COMPANY_NAME} {CURRENT_YEAR}
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  )
}

const Header = ({
  children,
  avatarUrl,
  firstName,
  lastName,
  meetingStart: videoMeetingStart,
  meetingUrl: videoMeetingUrl,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [height, setHeight] = useState(window.innerHeight)
  const [isActiveSession, setIsActiveSession] = useState(false)
  const [openVideoMeetingModal, setOpenVideoMeetingModal] = useState(false)
  const classes = useStyles(height)
  const handleHeight = useCallback((e) => {
    setHeight(e.target.innerHeight)
  }, [])
  const videoMeetingFormattedStart = videoMeetingStart
    ? `${format(new Date(videoMeetingStart), "d MMMM", {
        locale: sv,
      })}
kl ${format(new Date(videoMeetingStart), "HH.mm", {
        locale: sv,
      })}`
    : null

  useEffect(() => {
    window.addEventListener("resize", handleHeight)
    return () => {
      window.removeEventListener("resize", handleHeight)
    }
  }, [handleHeight])

  useEffect(() => {
    const checkIfActiveSession = async () => {
      const hasSession = await sessionHandler(false)
      setIsActiveSession(hasSession)
    }
    checkIfActiveSession()
  }, [])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <AppBar
            position="static"
            className={classes.appBar}
            data-testid="header"
          >
            <Toolbar>
              <Hidden mdUp>
                <IconButton onClick={toggleDrawer(!isOpen, setOpen)}>
                  <BurgerMenuIcon open={isOpen} />
                </IconButton>
              </Hidden>

              <Box className={classes.titleContainer}>
                <Logo />
              </Box>

              <Hidden mdDown>
                {isActiveSession && (
                  <Button
                    disableRipple
                    component={videoMeetingStart ? null : Link}
                    to={videoMeetingStart ? null : "/video"}
                    className={classes.videoButton}
                    onClick={
                      videoMeetingStart
                        ? () => setOpenVideoMeetingModal(true)
                        : null
                    }
                  >
                    <VideocamIcon />
                    {videoMeetingFormattedStart
                      ? `Kommande videomöte: ${videoMeetingFormattedStart}
                    `
                      : "Boka videomöte"}
                  </Button>
                )}
                <Button
                  onClick={() => window.open(config.about_us, "_blank")}
                  color="default"
                >
                  Om oss
                </Button>
                <Button
                  onClick={() => window.open(config.faq, "_blank")}
                  color="default"
                >
                  Vanliga frågor
                </Button>
                <Button
                  onClick={() => window.open(config.support, "_blank")}
                  color="default"
                >
                  Support
                </Button>
              </Hidden>

              <IconButton component={Link} to="/profile">
                <DefaultAvatar
                  src={avatarUrl}
                  name={`${firstName} ${lastName}`}
                />
              </IconButton>
            </Toolbar>
          </AppBar>

          <RenderDrawer
            className={classes.Drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            isOpen={isOpen}
            setOpen={setOpen}
            isActiveSession={isActiveSession}
            videoMeetingFormattedStart={videoMeetingFormattedStart}
            onVideoMeetingLinkClick={() => {
              setOpenVideoMeetingModal(true)
            }}
          />
          <Backdrop
            className={classes.backdrop}
            open={isOpen}
            onClick={toggleDrawer(false, setOpen)}
          />
        </div>
        <Container maxWidth="lg" disableGutters className={classes.container}>
          {children}
        </Container>
      </div>
      <VideoMeetingModal
        openModal={openVideoMeetingModal}
        onClose={() => setOpenVideoMeetingModal(false)}
        videoMeetingUrl={videoMeetingUrl}
      />
    </>
  )
}

const mapStateToProps = (state) => selectUserData(state)

export default connect(mapStateToProps)(Header)
