import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CircularProgress, Typography, Box, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import isEmpty from "lodash.isempty"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { getOnboarding } from "redux/onboarding/selectors"
import { fetchQuestions } from "redux/onboarding/actions"
import FormProgress from "./components/form/components/FormProgress"
import FormTypes from "./components/form/FormTypes"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  header: {
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.125rem",
    },
  },
  text: {
    textAlign: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
}))

const OnBoarding = ({
  getQuestions,
  questions,
  step,
  loading,
  error,
  userId,
  onboardingFinished,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()

  useEffect(() => {
    getQuestions(userId)
  }, [])

  if (onboardingFinished) {
    navigate("/patient", { replace: true })
  }

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={50} />
      </div>
    )
  }

  if (error) {
    return (
      <Box className={classes.loading}>
        <ErrorOutlineIcon color="primary" className={classes.icon} />
        <Typography variant="h4">Något gick fel!</Typography>
        <Button
          variant="contained"
          onClick={() => getQuestions(userId)}
          className={classes.errorButton}
        >
          Försök igen
        </Button>
      </Box>
    )
  }

  if (!isEmpty(questions)) {
    return (
      <>
        <FormProgress />
        <div className={classes.root}>
          <FormTypes formStep={questions[step]} />
        </div>
      </>
    )
  }

  return null
}

const mapStateToProps = (state) => getOnboarding(state)

const mapDispatchToProps = (dispatch) => ({
  getQuestions: (userId) => dispatch(fetchQuestions(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding)
