import React, { useEffect, createRef } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
import moment from "moment"
import "moment/locale/sv"
import IdleTimer from "react-idle-timer"
import { Provider } from "react-redux"
import store from "redux/store"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import theme from "theme"
import Chat from "pages/chat"
import Profile from "pages/profile"
import PaymentSuccess from "pages/paymentSuccess"
import PaymentCanceled from "pages/paymentCanceled"
import Header from "components/Header"
import BackHeader from "components/BackHeader"
import { getUserFromCookie } from "utils/getUserFromCookie"
import Alert from "components/Alert"
import { logout } from "redux/user"
import BeforeInstallPromptListenerWrapper from "components/BeforeInstallPromptListenerWrapper"
import Video from "pages/video"
import AuthRoute from "./AuthRoute"
import withClearCache from "../clearCache"

// 60 minutes
const IDLE_TIMER = 1000 * 60 * 60
const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function MainApp() {
  moment.locale("sv")
  const idleTimerRef = createRef()

  useEffect(() => {
    getUserFromCookie(store.dispatch)
  }, [])

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BeforeInstallPromptListenerWrapper>
              <Router>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <AuthRoute>
                        <Header>
                          <Chat />
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/video"
                    element={
                      <AuthRoute>
                        <Header>
                          <Video />
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <AuthRoute>
                        <BackHeader>
                          <Profile />
                        </BackHeader>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/payment-successful"
                    element={
                      <AuthRoute>
                        <Header>
                          <PaymentSuccess />
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/payment-canceled"
                    element={
                      <AuthRoute>
                        <Header>
                          <PaymentCanceled />
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Router>
            </BeforeInstallPromptListenerWrapper>

            <IdleTimer
              ref={idleTimerRef}
              element={document}
              onIdle={() => store.dispatch(logout())}
              timeout={IDLE_TIMER}
            />
            <Alert />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
}

export default App
