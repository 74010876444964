import { createSlice } from "@reduxjs/toolkit"

import {
  fetchCaregiver,
  fetchCaregiverAbsence,
  fetchCaregiverAvailabilities,
} from "./actions"

const initialState = {
  loading: true,
  error: false,
  data: {},
  absence: null,
}

const sliceName = "caregiver"

const caregiverSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCaregiver: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCaregiver.pending, (state) => {
        Object.assign(state, { error: false, loading: true })
      })
      .addCase(fetchCaregiver.rejected, (state) => {
        Object.assign(state, { error: true, loading: false })
      })
      .addCase(fetchCaregiver.fulfilled, (state, action) => {
        Object.assign(state, {
          data: action.payload,
          error: false,
          loading: false,
        })
      })
      .addCase(fetchCaregiverAbsence.fulfilled, (state, action) => {
        Object.assign(state, { absence: action.payload })
      })
      .addCase(fetchCaregiverAbsence.rejected, (state) => {
        Object.assign(state, { absence: null })
      })
      .addCase(fetchCaregiverAvailabilities.fulfilled, (state, action) => {
        Object.assign(state, { availabilities: action.payload })
      })
      .addCase(fetchCaregiverAvailabilities.rejected, (state) => {
        Object.assign(state, { availabilities: null })
      })
  },
})

export default caregiverSlice
