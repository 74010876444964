import transform from "lodash.transform"
import isEqual from "lodash.isequal"
import isObject from "lodash.isobject"

// obj Object compared
// campareObject Object to compare with

export function difference(object, base) {
  function changes(obj, compareObject) {
    return transform(obj, (result, value, key) => {
      const tmp = value === "" ? null : value
      if (!isEqual(tmp, compareObject[key])) {
        result[key] =
          isObject(tmp) && isObject(compareObject[key])
            ? changes(tmp, compareObject[key])
            : tmp
      }
    })
  }

  return changes(object, base)
}
