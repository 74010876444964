import { isDate, isBefore } from "date-fns"

/**
 *
 * @param {string|number} expirationDate
 * @returns {boolean}
 */

function isFreeCardValid(expirationDate) {
  const endDate = new Date(expirationDate)

  if (isDate(endDate)) {
    return isBefore(new Date(), endDate)
  }

  return false
}

export default isFreeCardValid
