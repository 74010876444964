import { createAsyncThunk } from "@reduxjs/toolkit"
import { getCaregiver } from "api/getCaregiver"
import { getCaregiverAbsence } from "api/getCaregiverAbsence"
import { getCaregiverAvailabilities } from "api/getCaregiverAvailabilities"
import { getDateConsideringDaylightSavingTime } from "utils/getDateConsideringDaylightSavingTime"

export const fetchCaregiver = createAsyncThunk(
  "caregiver/fetchCaregiver",
  async (userId) => {
    const { data } = await getCaregiver(userId)

    return data
  }
)

export const fetchCaregiverAbsence = createAsyncThunk(
  "caregiver/fetchCaregiverAbsence",
  async ({ caregiverId, ...params }, thunkApi) => {
    try {
      let absence
      const { data } = await getCaregiverAbsence(caregiverId, { ...params })
      const absences = data?.sort((a, b) => a.startAt.localeCompare(b.startAt))
      if (absences?.length > 0) {
        ;[absence] = absences
      }
      return absence
    } catch (e) {
      thunkApi.rejectWithValue(e)
    }
  }
)

export const fetchCaregiverAvailabilities = createAsyncThunk(
  "caregiver/fetchCaregiverAvailabilities",
  async (caregiverId, thunkApi) => {
    try {
      const { data } = await getCaregiverAvailabilities(caregiverId)
      const availabilities = data?.available.map((a) => ({
        ...a,
        slots: a.slots.map(({ start, end }) => ({
          start: getDateConsideringDaylightSavingTime(start),
          end: getDateConsideringDaylightSavingTime(end),
        })),
      }))

      return availabilities
    } catch (e) {
      thunkApi.rejectWithValue(e)
    }
  }
)
