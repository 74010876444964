import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Box, Typography, CircularProgress, Link } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { fetchCaregiverAvailabilities as getCaregiverAvailabilities } from "redux/caregiver"
import {
  fetchUserChatSession as getUserChatSession,
  fetchUserChats as getUserChats,
} from "redux/chat"
import VideoPaymentModal from "components/VideoPaymentModal"
import FrikortModal from "components/FrikortModal"
import moment from "moment"
import subHours from "date-fns/subHours"
import AvailableVideoTimes from "./components/AvailableVideoTimes"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    cursor: "pointer",
    color: theme.palette.dark_gray,
    textDecorationColor: theme.palette.dark_gray,
  },
  loadingSpinner: {
    color: theme.palette.primary.main,
    margin: "auto",
  },
  centered: {
    margin: "auto",
  },
}))

const Video = ({
  availabilities,
  fetchCaregiverAvailabilities,
  loading,
  user,
  fetchUserChatSession,
  fetchUserChats,
  caregiverData,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { userId, meetingStart: videoMeetingStart } = user
  const {
    firstName: caregiverFirstName,
    lastName: caregiverLastName,
    id: caregiverId,
  } = caregiverData
  const [isVideoPaymentModalOpen, setVideoPaymentModalOpen] = useState(false)
  const [isFrikortOpen, setFrikortOpen] = useState(false)
  const [selectedVideoCallSlot, setSelectedVideoCallSlot] = useState({
    start: null,
    end: null,
  })

  useEffect(() => {
    if (userId && !videoMeetingStart) {
      const loadAvailabilities = async () => {
        await fetchUserChatSession({ userId, limit: 1 })
        await fetchUserChats(userId)
        if (caregiverId) {
          await fetchCaregiverAvailabilities(caregiverId)
        }
      }

      loadAvailabilities()
    } else {
      // prevent user with booked video meeting from going directly to video page
      navigate("/")
    }
  }, [userId, caregiverId, videoMeetingStart])

  const renderNoAvailableTimes = () => (
    <>
      <Box>
        <Typography textAlign="left" fontWeight="bold">
          {caregiverFirstName} {caregiverLastName}
        </Typography>
      </Box>
      <Box className={classes.centered}>
        <Typography textAlign="center" fontWeight="bold">
          Inga tillgängliga tider -
        </Typography>
        <Typography textAlign="center">
          Vänligen maila info@dinpsykolog.se så hjälper vi dig
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Link href="/" underline="always" className={classes.link}>
          Tillbaka hem
        </Link>
      </Box>
    </>
  )

  const renderLoading = () => (
    <Box className={classes.loadingSpinner}>
      <CircularProgress className={classes.loadingSpinner} size={50} />
    </Box>
  )

  const openVideoPaymentModal = (start, end) => {
    const isDaylightSavingTime = moment(start).isDST()
    const startTime = isDaylightSavingTime ? start : subHours(start, 1)
    const endTime = isDaylightSavingTime ? end : subHours(end, 1)
    setSelectedVideoCallSlot({ start: startTime, end: endTime })
    setVideoPaymentModalOpen(true)
  }

  const renderContent = () => {
    if (loading || !availabilities) {
      return renderLoading()
    }
    if (availabilities.length) {
      return (
        <AvailableVideoTimes
          caregiverFirstName={caregiverFirstName}
          caregiverLastName={caregiverLastName}
          availabilities={availabilities}
          openModal={(videoCallStart, videoCallEnd) =>
            openVideoPaymentModal(videoCallStart, videoCallEnd)
          }
        />
      )
    }
    return renderNoAvailableTimes()
  }

  return (
    <Box className={classes.root}>
      {renderContent()}
      <VideoPaymentModal
        open={isVideoPaymentModalOpen}
        close={() => {
          setVideoPaymentModalOpen(false)
        }}
        userId={userId}
        onPaymentDecision={() => {
          setVideoPaymentModalOpen(false)
          setFrikortOpen(true)
        }}
        selectedVideoCallSlot={selectedVideoCallSlot}
        caregiverId={caregiverId}
      />
      <FrikortModal
        open={isFrikortOpen}
        close={() => setFrikortOpen(false)}
        goBack={() => {
          setFrikortOpen(false)
          setVideoPaymentModalOpen(true)
        }}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { caregiver, user } = state

  const { availabilities, data: caregiverData, loading } = caregiver

  return { availabilities, caregiverData, loading, user }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCaregiverAvailabilities: (caregiverId) =>
    dispatch(getCaregiverAvailabilities(caregiverId)),
  fetchUserChatSession: (data) => dispatch(getUserChatSession(data)),
  fetchUserChats: (userId) => dispatch(getUserChats(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Video)
