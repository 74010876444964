import React, { useState } from "react"
import { connect } from "react-redux"
import { differenceInDays, differenceInHours, format } from "date-fns"
import { useForm } from "react-hook-form"
import { Button, Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { Link, useNavigate } from "react-router-dom"
import profileValidation from "validation/profileValidation"
import isActiveSession from "utils/isActiveSession"
import { logout, updateUserProfile } from "redux/user"
import { unmatchCaregiver } from "api/unmatchCaregiver"
import { TouchClick } from "../../../components/TouchButton"
import DividerText from "../../../components/DividerText"
import Input from "../../../components/Input"
import UnmatchCaregiverConfirmationDialog from "./UnmatchCaregiverConfirmationDialog"

const useStyles = makeStyles((theme) => ({
  formInputContainer: {
    [theme.breakpoints.up("sm")]: {
      "& div": {
        marginRight: theme.spacing(4),
      },
      "& div:last-child": {
        marginRight: 0,
      },
    },
  },
  formInputs: {
    paddingBottom: theme.spacing(4),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  formSections: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.dark_gray,
  },
  formButtonsContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  formButtons: {
    width: 120,
    height: 50,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
  },
  accountStatus: {
    marginLeft: 4,
    marginBottom: theme.spacing(4),
  },
  sessionStatus: {
    fontWeight: "bold",
  },
  statusText: (session) => ({
    fontWeight: "normal",
    color: session ? theme.palette.success.dark : theme.palette.text.primary,
  }),
  newSessionButton: {
    marginTop: theme.spacing(1),
    width: "230px",
  },
  changeCaregiverContainer: {
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(0.5),
    width: "230px",
    "& button": {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    "& p": {
      fontSize: "0.7rem",
      fontStyle: "italic",
      fontWeight: "500",
      color: theme.palette.black,
      textAlign: "center",
    },
  },
  logOutButton: {
    display: "inline-block",
  },
}))

const resolver = (data) => {
  const { email, phoneNumber } = data
  const { error, value } = profileValidation({ email, phoneNumber })
  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const Form = ({ updateUser, userLogout, user, session, activeSession }) => {
  const { id: userId, email, firstName, lastName, phoneNumber } = user
  const classes = useStyles(activeSession)
  const navigate = useNavigate()
  const { control, handleSubmit, errors } = useForm({
    resolver,
  })
  const [
    openUnmatchCaregiverConfirmationDialog,
    setOpenUnmatchCaregiverConfirmationDialog,
  ] = useState(false)
  const [
    unmatchCaregiverConfirmationDialogError,
    setUnmatchCaregiverConfirmationDialogError,
  ] = useState(null)

  const onSubmit = (data) => {
    updateUser(data)
  }

  const sessionRemainingDays = () => {
    if (activeSession) {
      return differenceInDays(new Date(session.endedAt), new Date())
    }
    return null
  }

  const sessionRemainingHours = () => {
    if (activeSession) {
      return differenceInHours(new Date(session.endedAt), new Date())
    }
    return null
  }

  const sessionEndsAt = () => {
    if (activeSession) {
      return format(new Date(session.endedAt), "yyyy-MM-dd HH:mm")
    }
    return null
  }

  const getSessionRemainingText = () => {
    if (!activeSession) return null

    if (sessionRemainingDays() > 0) {
      return `${sessionRemainingDays()} 
      ${sessionRemainingDays() === 1 ? " dag" : " dagar"} 
      kvar (${sessionEndsAt()})`
    }

    if (sessionRemainingHours() > 1) {
      return `${sessionRemainingHours()}h kvar (${sessionEndsAt()})`
    }
    return `< 1h kvar (${sessionEndsAt()})`
  }

  const handleUnmatchFromCaregiver = async () => {
    try {
      await unmatchCaregiver(userId)
      window.location.replace("/")
    } catch (error) {
      setUnmatchCaregiverConfirmationDialogError(
        "Något gick fel, vänligen försök igen inom kort. Om problemen består, kontakta info@dinpsykolog.se."
      )
    }
  }

  const handleOpenUnmatchCaregiverConfirmationDialog = () => {
    setUnmatchCaregiverConfirmationDialogError(null)
    setOpenUnmatchCaregiverConfirmationDialog(true)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.formInputContainer}>
        <Input
          className={classes.formInputs}
          name="firstName"
          label="Förnamn"
          defaultValue={firstName}
          control={control}
          errors={errors}
          disabled
        />
        <Input
          className={classes.formInputs}
          name="lastName"
          label="Efternamn"
          defaultValue={lastName}
          control={control}
          errors={errors}
          disabled
        />
      </Box>

      <DividerText className={classes.formSections} text="Kontaktuppgifter" />

      <Box className={classes.formInputContainer}>
        <Input
          className={classes.formInputs}
          name="email"
          label="Email"
          defaultValue={email}
          control={control}
          errors={errors}
        />
        <Input
          className={classes.formInputs}
          name="phoneNumber"
          label="Telefon"
          type="tel"
          defaultValue={phoneNumber || ""}
          control={control}
          errors={errors}
        />
      </Box>

      <DividerText className={classes.formSections} text="Session" />

      <Box className={classes.accountStatus}>
        <Typography variant="body1" className={classes.sessionStatus}>
          {activeSession ? "Aktiv" : "Inaktiv"}
        </Typography>
        <Typography className={classes.statusText}>
          {getSessionRemainingText()}
        </Typography>
        {!activeSession && (
          <Button
            className={classes.newSessionButton}
            size="large"
            variant="contained"
            onClick={() => navigate("/")}
          >
            Starta en ny session
          </Button>
        )}
      </Box>

      <DividerText className={classes.formSections} text="Konto" />
      {!session && (
        <>
          <Box className={classes.changeCaregiverContainer}>
            <Button
              size="large"
              variant="contained"
              onClick={() => handleOpenUnmatchCaregiverConfirmationDialog()}
            >
              Byt psykolog
            </Button>
            <Typography>Endast möjligt innan första besöket</Typography>
          </Box>
          <UnmatchCaregiverConfirmationDialog
            openDialog={openUnmatchCaregiverConfirmationDialog}
            onClose={() => setOpenUnmatchCaregiverConfirmationDialog(false)}
            onUnmatch={handleUnmatchFromCaregiver}
            error={unmatchCaregiverConfirmationDialogError}
          />
        </>
      )}
      <Box className={classes.formSections}>
        <TouchClick
          className={classes.logOutButton}
          display="block"
          onClick={() => {
            userLogout()
          }}
        >
          <ExitToAppIcon color="primary" />
          <Box ml={1}>
            <Typography color="primary">Logga ut</Typography>
          </Box>
        </TouchClick>
      </Box>

      <Box className={classes.formButtonsContainer}>
        <Button
          className={classes.formButtons}
          variant="outlined"
          component={Link}
          to="/"
          color="default"
        >
          Avbryt
        </Button>
        <Button
          className={classes.formButtons}
          variant="contained"
          type="submit"
        >
          Spara
        </Button>
      </Box>
    </form>
  )
}

const mapStateToProps = (state) => {
  const { chat, user } = state
  const { session } = chat || {}

  return { user, session, activeSession: isActiveSession(session) }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUserProfile(data)),
  userLogout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
