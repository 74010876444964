import React, { useState } from "react"
import { arrayOf, shape, string, any } from "prop-types"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { showAlert as displayAlert } from "redux/common"
import {
  nextStep as nextFormStep,
  prevStep as prevFormStep,
} from "redux/onboarding"
import { FormGroup, Typography, FormHelperText } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { answerOnboardingQuestion } from "api/answerOnboardingQuestion"
import isEmpty from "lodash.isempty"
import singleAnswerSchema from "validation/singleAnswerValidation"
import { getOnboarding } from "redux/onboarding/selectors"
import config from "config"
import FormStepper from "./components/FormStepper"
import RadioButtons from "./components/RadioButtons"
import PausedOnboarding from "../PausedOnboarding"

const userInformationText = "Information till dig som det berör"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  formContainer: {
    padding: theme.spacing(3),
    flexGrow: 1,
    overflow: "auto",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  descriptionTitle: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  description: {
    marginBottom: theme.spacing(2),
    fontWeight: 400,
  },
  descriptionWrapper: {
    marginBottom: theme.spacing(3),
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const resolver = (data, context) => {
  const { type } = context

  const { error, value } = singleAnswerSchema(data, type)

  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce((previous, currentError) => {
        currentError.message = "Välj ett alternativ"
        return {
          ...previous,
          [currentError.path[0]]: currentError,
        }
      }, {})
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const SingleAnswerForm = ({
  showAlert,
  prevStep,
  nextStep,
  data,
  step,
  answeredQuestions,
  userId,
}) => {
  const classes = useStyles()
  const [isLoadingNextStep, setLoadingNextStep] = useState(false)
  const [onHoldData, setOnHoldData] = useState(null)
  const [confirmationData, setConfirmationData] = useState(null)
  const { question = "", answers = [], id: questionId } = data
  const [defaultValue = null] = answeredQuestions[step] || []
  const [answer = {}] = answers
  const { key, type } = answer
  const chatOrVideoPreferenceStep = step === 5

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver,
    context: {
      type,
    },
    reValidateMode: "onSubmit",
    defaultValues: { [key]: defaultValue },
  })

  const onSubmit = (formData) =>
    new Promise(() => {
      const [formAnswer] = Object.values(formData)
      const fullAnswerData = answers.filter((item) => item.value === formAnswer)
      const answerChanged = !isEmpty(fullAnswerData)

      if (answerChanged) {
        const [{ allowContinue, onHoldReason, confirm, value }] = fullAnswerData
        if (allowContinue) {
          answerAndStep(formAnswer, userId, questionId, formData)
        } else if (key === "visitType" && value === "video") {
          answerAndRedirectToKaddioBooking(formAnswer, userId, questionId)
        } else if (onHoldReason) {
          setOnHoldData(onHoldReason)
        } else if (confirm) {
          setConfirmationData({
            confirmTextData: confirm,
            actionData: {
              answer: formAnswer,
              userId,
              questionId,
              data: formData,
            },
          })
        }
      } else {
        nextStep()
      }
    })

  function answerAndStep(formAnswer, uid, qid, formData) {
    answerOnboardingQuestion({
      answer: formAnswer,
      userId: uid,
      questionId: qid,
    })
      .then(() => {
        setLoadingNextStep(true)
        setTimeout(() => {
          nextStep(formData)
        }, 500)
      })
      .catch(() => {
        showAlert({ type: "error", message: "Något gick fel" })
      })
  }

  function answerAndRedirectToKaddioBooking(formAnswer, uid, qid) {
    answerOnboardingQuestion({
      answer: formAnswer,
      userId: uid,
      questionId: qid,
    })
      .then(() => {
        window.location.href = config.kaddioBookingUrl
      })
      .catch(() => {
        showAlert({ type: "error", message: "Något gick fel" })
      })
  }

  function prevQuestion() {
    prevStep()
  }

  return (
    <div>
      {onHoldData && (
        <PausedOnboarding
          allowContinue={false}
          exitReason={onHoldData}
          onCloseAction={() => {
            setOnHoldData(null)
            reset()
          }}
        />
      )}
      {!onHoldData && confirmationData && (
        <PausedOnboarding
          allowContinue
          title={userInformationText}
          confirmTextData={confirmationData.confirmTextData}
          onCloseAction={() => {
            setConfirmationData(null)
            reset()
          }}
          onAnswerConfirmed={() => {
            answerAndStep(
              confirmationData.actionData.answer,
              confirmationData.actionData.userId,
              confirmationData.actionData.questionId,
              confirmationData.actionData.data
            )
          }}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <div className={classes.formContainer}>
          <Typography
            component="h1"
            variant="h5"
            display="block"
            className={classes.title}
            data-testid="onboarding_title"
          >
            {step + 1}. {question}
          </Typography>
          {chatOrVideoPreferenceStep && (
            <div className={classes.descriptionWrapper}>
              <Typography variant="h6" className={classes.descriptionTitle}>
                IKBT via chatt
              </Typography>
              <Typography
                component="body1"
                variant="body1"
                className={classes.description}
              >
                I nästa steg får du välja en psykolog som passar dina behov och
                komma igång med en behandlingsplan via chatt.
              </Typography>
              <Typography variant="h6" className={classes.descriptionTitle}>
                Boka videosamtal
              </Typography>
              <Typography
                component="body1"
                variant="body1"
                className={classes.description}
              >
                I nästa steg kommer du till en kalender där du kan boka
                videosamtal med en psykolog.
              </Typography>
            </div>
          )}
          <FormGroup>
            <RadioButtons
              defaultValue={defaultValue}
              buttons={answers}
              name={key}
              control={control}
              disabled={isSubmitting}
              onRadioButtonSelected={() => handleSubmit(onSubmit)()}
            />
            {!!errors?.[key] && (
              <FormHelperText className={classes.error}>
                {errors[key].message}
              </FormHelperText>
            )}
          </FormGroup>
        </div>
        <FormStepper
          disabled={isSubmitting}
          isLoadingNextStep={isLoadingNextStep}
          prevClick={prevQuestion}
        />
      </form>
    </div>
  )
}

const mapStateToProps = (state) => getOnboarding(state)

const mapDispatchToProps = {
  showAlert: displayAlert,
  nextStep: nextFormStep,
  prevStep: prevFormStep,
}

SingleAnswerForm.propTypes = {
  data: shape({
    question: string.isRequired,
    answers: arrayOf(
      shape({
        key: string.isRequired,
        type: string,
        text: string.isRequired,
        value: any.isRequired,
      })
    ),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleAnswerForm)
