/* eslint-disable no-nested-ternary */
import { Box, Button, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { connect } from "react-redux"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import format from "date-fns/format"
import { sv } from "date-fns/locale"
import VideoMeetingModal from "./VideoMeetingModal"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-table",
  },
  wrapper: {
    padding: [theme.spacing(1, 0)],
    background: [theme.palette.light_gray],
  },
  questionText: {
    color: [theme.palette.primary.main],
    fontWeight: "600",
    padding: [theme.spacing(2, 2, 0, 2)],
    [theme.breakpoints.down("md")]: {
      padding: [theme.spacing(1, 3, 0, 3)],
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: [theme.spacing(1, 2, 0, 2)],
    },
  },
  questionSubtitle: {
    color: [theme.palette.primary.main],
    fontWeight: "500",
    padding: [theme.spacing(2, 2, 0, 2)],
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
      padding: [theme.spacing(1, 3, 0, 3)],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [theme.spacing(0.5, 2, 0, 2)],
    },
  },
  buttonContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    padding: [theme.spacing(2)],
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: [theme.spacing(1)],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [theme.spacing(1, 2, 1, 2)],
    },
  },
  button: {
    border: "none",
    width: "40%",
    borderRadius: "20px",
    "&:last-of-type": {
      marginLeft: theme.spacing(2),
    },
    background: [theme.palette.primary.main],
    "&:hover": {
      background: [theme.palette.primary.dark],
    },
    [theme.breakpoints.down("md")]: {
      width: "70%",
      height: 40,
      "&:last-of-type": {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(0),
      },
    },
  },
  buttonText: {
    textTransform: "none",
    color: [theme.palette.primary.contrastText],
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.125rem",
    },
  },
  formLink: {
    textDecoration: "underline",
    color: [theme.palette.primary.dark],
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  formLinkText: {
    fontWeight: "500",
  },
}))

const ContinueSession = ({
  showPaymentModal,
  caregiverFirstName,
  session,
  videoMeetingStart,
  videoMeetingUrl,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [openVideoMeetingModal, setOpenVideoMeetingModal] = useState(false)
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <Typography
            className={classes.questionText}
            align="center"
            variant="h5"
          >
            {videoMeetingStart
              ? `Du har ett videomöte bokat den ${format(
                  new Date(videoMeetingStart),
                  "d MMMM",
                  {
                    locale: sv,
                  }
                )} kl ${format(new Date(videoMeetingStart), "HH.mm", {
                  locale: sv,
                })}`
              : session
              ? `Vill du fortsätta kontakten med ${caregiverFirstName}?`
              : "Boka ett videomöte eller starta chatt"}
          </Typography>
          <Typography
            className={classes.questionSubtitle}
            variant="subtitle1"
            align="center"
          >
            {videoMeetingStart
              ? // eslint-disable-next-line max-len
                "Efter ditt videomöte kommer denna chattvy öppnas upp och du får då möjlighet att boka ett nytt videomöte eller påbörja IKBT via chatt."
              : session
              ? // eslint-disable-next-line max-len
                "Ditt besök är nu slut och du har därmed tagit ett steg närmare att få må bättre. Du kan nu betala för nästa besök."
              : "Nu kan du starta ditt besök genom att boka en tid för videomöte eller börja chatta (IKBT)"}
          </Typography>
          <Box className={classes.buttonContainer}>
            {videoMeetingStart ? (
              <Button
                onClick={() => setOpenVideoMeetingModal(true)}
                className={classes.button}
                variant="contained"
              >
                <Typography className={classes.buttonText} variant="h6">
                  Länk till videomöte
                </Typography>
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => navigate("/video")}
                  className={classes.button}
                  variant="contained"
                >
                  <Typography className={classes.buttonText} variant="h6">
                    Boka videomöte
                  </Typography>
                </Button>
                <Button
                  onClick={() => showPaymentModal(true)}
                  className={classes.button}
                  variant="contained"
                >
                  <Typography className={classes.buttonText} variant="h6">
                    Börja chatta
                  </Typography>
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <VideoMeetingModal
        openModal={openVideoMeetingModal}
        onClose={() => setOpenVideoMeetingModal(false)}
        videoMeetingUrl={videoMeetingUrl}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { caregiver } = state
  const { data } = caregiver

  return {
    caregiverFirstName: data.firstName,
  }
}

export default connect(mapStateToProps)(ContinueSession)
