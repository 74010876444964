import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import TagManager from "react-gtm-module"
import config from "config"
import Router from "./router"
import * as serviceWorker from "./serviceWorker"
import "typeface-montserrat"

const { gtmId, isDev } = config
// Initialize google tag manager if its production env
const tagManagerArgs = {
  gtmId,
}

if (!isDev) {
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(<Router />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
