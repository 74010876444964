import { connect } from "react-redux"
import { getOnboardingStep } from "redux/onboarding/selectors"
import {
  nextStep as nextFormStep,
  prevStep as prevFormStep,
} from "redux/onboarding"
import { Typography, Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import FormStepper from "./components/FormStepper"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  container: {
    padding: theme.spacing(3),
    flexGrow: 1,
    overflow: "auto",
  },
  title: {
    fontWeight: 600,
  },
  subtitleContainer: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: "1.2rem",
    fontStyle: "italic",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  wrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  infoContainer: {
    textAlign: "justify",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      marginRight: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      "&:last-of-type": {
        marginTop: theme.spacing(2),
      },
    },
    "& .MuiTypography-gutterBottom": {
      marginBottom: theme.spacing(2),
    },
  },
  numberedSubtitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}))

const ServiceInfoForm = ({ prevStep, step, nextStep }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          component="h1"
          variant="h5"
          display="block"
          className={classes.title}
          data-testid="onboarding_title"
        >
          {step + 1}. Videosamtal eller chatt med iKBT?
        </Typography>
        <Box className={classes.subtitleContainer}>
          <Typography variant="body2" className={classes.subtitle}>
            (Du väljer alternativ i nästa steg)
          </Typography>
        </Box>
        <Box className={classes.wrap}>
          <Box className={classes.infoContainer}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ fontWeight: 600 }}
            >
              Så fungerar videosamtal
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.numberedSubtitle}
            >
              1. Boka tid
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              Börja med att boka en tid för video.
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.numberedSubtitle}
            >
              2. Bedömning
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              Under ditt första besök får då berätta hur du mår och vad du söker
              hjälp för att sedan tillsammans med din psykolog hitta en
              behandling som är lämplig för dig.
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.numberedSubtitle}
            >
              3. Behandling
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              Under ditt första besök får då berätta hur du mår och vad du söker
              hjälp för att sedan tillsammans med din.
            </Typography>
          </Box>
          <Box className={classes.infoContainer}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ fontWeight: 600 }}
            >
              Så fungerar chatt med iKBT
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.numberedSubtitle}
            >
              1. Boka tid
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              Börja med att boka en tid för video eller chattsamtal.
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.numberedSubtitle}
            >
              2. Bedömning
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              Under ditt första besök får då berätta hur du mår och vad du söker
              hjälp för att sedan tillsammans med din psykolog hitta en
              behandling som är lämplig för dig.
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.numberedSubtitle}
            >
              3. Behandling
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              Under ditt första besök får då berätta hur du mår och vad du söker
              hjälp för att sedan tillsammans med din.
            </Typography>
          </Box>
        </Box>
      </div>
      <FormStepper prevClick={prevStep} nextClick={nextStep} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const step = getOnboardingStep(state)

  return { step }
}

const mapDispatchToProps = {
  nextStep: nextFormStep,
  prevStep: prevFormStep,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceInfoForm)
