import { createSlice } from "@reduxjs/toolkit"
import { defaultPagination } from "utils"
import {
  setUserChatSession,
  fetchUserChatSession,
  fetchUserChats,
  fetchEntries,
} from "./actions"

const initialState = {
  loading: true,
  error: false,
  id: null,
  messages: [],
  messagesCount: 0,
  messagesLoading: false,
  messagesPagination: defaultPagination,
  session: null,
}

const sliceName = "chat"

const chatSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    postMessageInChat: (state, action) => {
      Object.assign(state, { messages: [...state.messages, action.payload] })
    },
    setChatMessagesPagination: (state, action) => {
      Object.assign(state, { messagesPagination: action.payload })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserChatSession.fulfilled, (state, action) => {
        Object.assign(state, { session: action.payload })
      })
      .addCase(fetchUserChatSession.rejected, (state) => {
        Object.assign(state, { session: null })
      })
      .addCase(fetchUserChatSession.fulfilled, (state, action) => {
        Object.assign(state, { session: action.payload })
      })
      .addCase(fetchUserChats.pending, (state) => {
        Object.assign(state, { error: false, loading: true })
      })
      .addCase(fetchUserChats.rejected, (state) => {
        Object.assign(state, { error: true, loading: false })
      })
      .addCase(fetchUserChats.fulfilled, (state, action) => {
        Object.assign(state, {
          ...action.payload,
          loading: false,
          error: false,
        })
      })
      .addCase(fetchEntries.pending, (state) => {
        Object.assign(state, { messagesLoading: true, error: false })
      })
      .addCase(fetchEntries.fulfilled, (state, action) => {
        Object.assign(state, {
          messages: [...action.payload.data, ...state.messages],
          messagesCount: action.payload.totalCount,
          messagesLoading: false,
        })
      })
      .addCase(fetchEntries.rejected, (state) => {
        Object.assign(state, { messagesLoading: false, error: true })
      })
  },
})

export default chatSlice
