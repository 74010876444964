import React from "react"
import { connect } from "react-redux"
import { Box, Hidden, CircularProgress, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DefaultAvatar from "components/DefaultAvatar"

const IMAGE_SIZE = 150
const OUTER_BORDER = 5
const INNER_BORDER = 2

const useStyles = makeStyles((theme) => ({
  "@keyframes outer-border-pulsate": {
    "0%": {
      borderWidth: OUTER_BORDER,
    },
    "50%": {
      borderWidth: OUTER_BORDER * 4,
    },
    "100%": {
      borderWidth: OUTER_BORDER,
    },
  },
  "@keyframes inner-border-pulsate": {
    "0%": {
      borderWidth: INNER_BORDER,
    },
    "50%": {
      borderWidth: INNER_BORDER * 4,
    },
    "100%": {
      borderWidth: INNER_BORDER,
    },
  },
  "@-webkit-keyframes outer-border-pulsate": {
    "0%": {
      borderWidth: OUTER_BORDER,
    },
    "50%": {
      borderWidth: OUTER_BORDER * 4,
    },
    "100%": {
      borderWidth: OUTER_BORDER,
    },
  },
  "@-webkit-keyframes inner-border-pulsate": {
    "0%": {
      borderWidth: INNER_BORDER,
    },
    "50%": {
      borderWidth: INNER_BORDER * 4,
    },
    "100%": {
      borderWidth: INNER_BORDER,
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  wrapper: {
    position: "relative",
    height: IMAGE_SIZE + OUTER_BORDER * 4 * 2 + INNER_BORDER * 4 * 2,
    width: IMAGE_SIZE + OUTER_BORDER * 4 * 2 + INNER_BORDER * 4 * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  caregiverOuterCircle: {
    display: "block",
    borderRadius: "100%",
    animation: "$outer-border-pulsate 2s infinite",
    "-webkit-animation": "$outer-border-pulsate 2s infinite",
    borderWidth: OUTER_BORDER,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  caregiverInnerCircle: {
    display: "block",
    borderRadius: "100%",
    animation: "$inner-border-pulsate 2s infinite",
    "-webkit-animation": "$inner-border-pulsate 2s infinite",
    borderWidth: INNER_BORDER,
    borderStyle: "solid",
    borderColor: "#fff",
  },
  header: {
    marginTop: theme.spacing(2),
  },
  text: {},
}))

const ChatLoading = ({ avatarUrl, firstName, lastName }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <CircularProgress size={50} color="primary" />
      </Hidden>
      <Hidden smUp>
        <Box className={classes.wrapper}>
          <Box className={classes.caregiverOuterCircle}>
            <Box className={classes.caregiverInnerCircle}>
              <DefaultAvatar
                size={IMAGE_SIZE}
                src={avatarUrl}
                name={`${firstName} ${lastName}`}
              />
            </Box>
          </Box>
        </Box>
        <Typography className={classes.header} variant="h4">
          {firstName} {lastName}
        </Typography>
        <Typography className={classes.text}>
          Laddar chatt med din psykolog...
        </Typography>
      </Hidden>
    </Box>
  )
}

const mapStateToProps = (state) => {
  const {
    caregiver: { data },
  } = state
  const { avatarUrl = null, firstName = "", lastName = "" } = data || {}

  return { avatarUrl, firstName, lastName }
}

export default connect(mapStateToProps)(ChatLoading)
