import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { fetchCheckoutSession } from "api/fetchCheckoutSession"
import { Modal, Box, Typography, Button, Link } from "@mui/material"
import { makeStyles } from "@mui/styles"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import { getUserId } from "redux/user"
import { isSameMonth, format, addBusinessDays } from "date-fns"
import { sv } from "date-fns/locale"
import { sessionType } from "utils/sessionType"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    backgroundColor: theme.palette.lighter_gray,
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    outline: "none",
    maxWidth: "30%",

    [theme.breakpoints.down("lg")]: {
      maxWidth: "50%",
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      maxHeight: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      alignContent: "center",
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: "100%",
      maxHeight: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      alignContent: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(1),
    },
  },
  button: {
    display: "flex",
    flex: 1,
    marginRight: theme.spacing(2),
    "&:last-of-type": {
      marginRight: 0,
    },
    padding: theme.spacing(2),
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      width: "100%",
      "&:last-of-type": {
        marginTop: theme.spacing(1),
      },
    },
  },
  freecardLink: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
  },
  buttonTitle: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "1.2rem",
    },
  },
  feeTitle: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  text: {
    color: theme.palette.black,
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      fontSize: "0.9rem",
    },
  },
  reasonsList: {
    fontSize: "1rem",
    lineHeight: 1.6,
    listStyleType: "square",
  },
  box: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  absenceBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
    paddingBottom: theme.spacing(1),
  },
  closeIcon: {
    color: theme.palette.primary.main,
  },
  absenceText: {
    fontSize: "1.1rem",
    fontWeight: 600,
    color: theme.palette.warning.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  absenceCaption: {
    fontSize: "0.9rem",
    fontWeight: 500,
    paddingTop: theme.spacing(0.5),
    color: theme.palette.warning.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
}))

const PaymentModal = ({
  session,
  caregiver,
  open,
  close,
  userId,
  onPaymentDecision,
  caregiverFirstName,
  absence,
  daysUntilAbsence,
  absenceDuration,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dateFormat = "d MMM"

  useEffect(() => {
    const popStateAction = () => {
      navigate("/")
    }
    window.addEventListener("popstate", popStateAction)
    return () => window.removeEventListener("popstate", popStateAction)
  }, [])

  const redirectToCheckout = async () => {
    const {
      data: { url },
    } = await fetchCheckoutSession({ type: sessionType.CHAT })
    window.location.href = url
  }

  const getAbsenceHeader = () => {
    let header = `${caregiverFirstName} ${
      daysUntilAbsence <= 0 ? " är ledig " : " kommer vara ledig "
    }`

    if (absenceDuration === 0) {
      header += format(new Date(absence.startAt), dateFormat, { locale: sv })
    } else {
      header += format(
        new Date(absence.startAt),
        isSameMonth(new Date(absence.startAt), new Date(absence.endAt))
          ? "d"
          : dateFormat,
        { locale: sv }
      )
      header += " - "
      header += format(new Date(absence.endAt), dateFormat, { locale: sv })
    }

    header = header.replaceAll(".", "")

    return header
  }

  const getAbsenceCaption = () => {
    let caption = ` Du kan fortsätta kontakten redan nu om du önskar. ${caregiverFirstName} är tillbaka den ${format(
      addBusinessDays(new Date(absence.endAt), 1),
      dateFormat,
      {
        locale: sv,
      }
    )}. 
   Maila info@dinpsykolog.se om du har några frågor eller vill byta psykolog.`
    caption = caption.replaceAll("..", ".")

    return caption
  }

  return (
    <Modal className={classes.root} open={open} onClose={close}>
      <Box className={classes.wrapper}>
        <Box className={classes.iconContainer}>
          <CancelOutlinedIcon
            className={classes.closeIcon}
            onClick={close}
            fontSize="large"
          />
        </Box>
        {session?.caregiverId !== caregiver.data.userId ? (
          <Typography variant="h5" className={classes.title}>
            Börja chatta med din psykolog {caregiverFirstName}
          </Typography>
        ) : (
          <Typography variant="h5" className={classes.title}>
            Fortsätt chatta med din psykolog {caregiverFirstName}
          </Typography>
        )}
        {absence && daysUntilAbsence <= 7 && (
          <Box className={classes.absenceBox}>
            <Typography className={classes.absenceText} variant="h6">
              {getAbsenceHeader()}
            </Typography>
            <Typography className={classes.absenceCaption}>
              {daysUntilAbsence <= 0 ? getAbsenceCaption() : ""}
            </Typography>
          </Box>
        )}
        <Box className={classes.box} />
        <Typography variant="h6" className={classes.title}>
          Välj betalsätt
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            onClick={() => {
              navigate("/")
              redirectToCheckout()
            }}
          >
            <Typography variant="h5" className={classes.buttonTitle}>
              Kort / Faktura
            </Typography>
          </Button>
          <Link
            className={classes.freecardLink}
            component="button"
            variant="body2"
            onClick={() => onPaymentDecision(userId)}
          >
            <Typography variant="subtitle2">Jag har frikort</Typography>
          </Link>
        </Box>
        <Box className={classes.box} />
        <Box className={classes.feeContainer}>
          <Typography variant="h6" className={classes.feeTitle}>
            Patientavgift 100kr/vecka
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Du får chatta med din psykolog i 7 dagar från det att du betalat. Du
            kan förvänta dig svar inom 24 timmar på vardagar. Efter att en vecka
            har gått kan du välja att påbörja ett nytt besök.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { session } = state.chat
  const { caregiver } = state
  const { userId } = getUserId(state)
  const { data } = caregiver

  return {
    session,
    caregiver,
    userId,
    caregiverFirstName: data.firstName,
  }
}

export default connect(mapStateToProps)(PaymentModal)
