import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Box, Typography, Button, CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import useScript from "react-script-hook"
import { InitVardenSeTrackingCallback } from "utils/initVardenSe"
import TagManager from "react-gtm-module"
import config from "config"
import { sessionType } from "utils/sessionType"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  header: {
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.125rem",
    },
  },
  text: {
    textAlign: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
      width: "90%",
    },
  },
  button: {
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(2.5),
  },
}))

function sendAdtractionData(transactionId) {
  const data = {
    dataLayer: {
      transactionId,
      transactionTotal: 0,
    },
  }

  TagManager.dataLayer(data)
}

const PaymentSuccess = ({ user }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paymentSessionType = parseInt(searchParams.get("type"))
  const [loading, setLoading] = useState(true)
  const { id: userId } = user
  const { isDev } = config
  const isChatSessionType = paymentSessionType === sessionType.CHAT

  // eslint-disable-next-line
  const [_, errorVardenSe] = useScript({
    src: "https://varden-scripts.s3-eu-west-1.amazonaws.com/booking/vbnotification.js",
    checkForExisting: true,
    onload: () => console.log("vardense loaded"),
  })

  useEffect(() => {
    if (!isDev) {
      sendAdtractionData(userId)

      if (!errorVardenSe) {
        InitVardenSeTrackingCallback(userId)
      } else {
        console.warn("InitVardenSeTrackingCallback script not loaded")
      }
    }

    setLoading(false)
    const popStateAction = () => {
      navigate("/")
    }
    window.addEventListener("popstate", popStateAction)
    return () => window.removeEventListener("popstate", popStateAction)
  }, [])

  if (loading) {
    return (
      <Box className={classes.root}>
        <CircularProgress size={50} color="primary" />
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <CheckCircleOutlineIcon color="primary" className={classes.icon} />
      <Typography color="primary" className={classes.header}>
        Tack för din betalning!
      </Typography>
      <Typography className={classes.text}>
        {isChatSessionType
          ? "Nu kan du börja skriva med din psykolog"
          : "Du har nu fått en länk till videomötet skickat till din e-post"}
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => navigate("/")}
      >
        {isChatSessionType ? "Börja chatta" : "Till min hemskärm"}
      </Button>
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { user } = state

  return { user }
}

export default connect(mapStateToProps)(PaymentSuccess)
