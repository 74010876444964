import React from "react"
import { arrayOf, shape, string } from "prop-types"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { showAlert as displayAlert } from "redux/common"
import {
  nextStep as nextFormStep,
  prevStep as prevFormStep,
} from "redux/onboarding"
import { FormGroup, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { answerOnboardingQuestion } from "api/answerOnboardingQuestion"
import { difference } from "utils/compareObject"
import isEmpty from "lodash.isempty"
import freeTextSchema from "validation/freeTextValidation"
import Input from "components/Input"
import { getOnboarding } from "redux/onboarding/selectors"
import FormStepper from "./components/FormStepper"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  formContainer: {
    padding: theme.spacing(3),
    flexGrow: 1,
    overflow: "auto",
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
  },
  subTitle: {
    marginTop: theme.spacing(2),
    fontStyle: "italic",
    fontSize: "14px",
  },
}))

const resolver = (data, validationContext) => {
  const { type } = validationContext
  const { error, value } = freeTextSchema(data, type)
  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const FreeTextForm = ({
  showAlert,
  prevStep,
  nextStep,
  data,
  step,
  answeredQuestions,
  userId,
}) => {
  const classes = useStyles()
  const { question = "", subTitle = "", answers = [], id: questionId } = data
  const [defaultValue = ""] = answeredQuestions[step] || []
  const [answer = {}] = answers
  const { key, type } = answer
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    context: { type },
  })

  const onSubmit = (formData) =>
    new Promise(() => {
      const formAnswer = Object.values(formData)[0]
      const answerDiff = difference(
        { [key]: formAnswer },
        { [key]: defaultValue }
      )
      const answerChanged = !isEmpty(answerDiff)

      if (answerChanged) {
        answerOnboardingQuestion({ answer: formAnswer, userId, questionId })
          .then(() => {
            nextStep(formData)
          })
          .catch(() => {
            showAlert({ type: "error", message: "Något gick fel" })
          })
      } else {
        nextStep()
      }
    })

  function prevQuestion() {
    prevStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div className={classes.formContainer}>
        <Typography
          component="h1"
          variant="h5"
          display="block"
          className={classes.title}
          data-testid="onboarding_title"
        >
          {step + 1}. {question}
        </Typography>
        <FormGroup>
          <Input
            name={key}
            defaultValue={defaultValue}
            control={control}
            errors={errors}
          />
        </FormGroup>
        <Typography
          variant="h6"
          display="block"
          className={classes.subTitle}
          data-testid="onboarding_sub_title"
        >
          {subTitle}
        </Typography>
      </div>
      <FormStepper disabled={isSubmitting} prevClick={prevQuestion} />
    </form>
  )
}

const mapStateToProps = (state) => getOnboarding(state)

const mapDispatchToProps = {
  nextStep: nextFormStep,
  prevStep: prevFormStep,
  showAlert: displayAlert,
}

FreeTextForm.propTypes = {
  data: shape({
    question: string.isRequired,
    subTitle: string.isRequired,
    answers: arrayOf(
      shape({
        key: string.isRequired,
        type: string,
        text: string,
      })
    ),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeTextForm)
