import React from "react"
import { Link } from "react-router-dom"
import { Box, Typography, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import WarningIcon from "@mui/icons-material/Warning"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  header: {
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.125rem",
    },
  },
  text: {
    textAlign: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

const PaymentCanceled = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <WarningIcon color="error" className={classes.icon} />
      <Typography color="primary" className={classes.header}>
        Betalning avbruten
      </Typography>
      <Typography className={classes.text}>
        Du har avbrutit din transaktion
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        component={Link}
        to="/"
      >
        Tillbaka
      </Button>
    </Box>
  )
}

export default PaymentCanceled
