import React from "react"
import { Controller } from "react-hook-form"
import {
  RadioGroup as MuiRadioGroup,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  Box as MuiBox,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { alpha } from "@mui/material/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  radio: {
    display: "none",
  },
  label: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > span:last-child": {
      paddingRight: 16,
      paddingLeft: 16,
      paddingTop: 6,
      paddingBottom: 6,
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
      color: theme.palette.primary.main,
    },
    "& > span:first-child.Mui-checked+span": {
      backgroundColor: theme.palette.primary.main,
      border: "none",
      color: "#fff",
    },
  },
}))

const RadioButtons = ({
  name,
  buttons,
  control,
  onRadioButtonSelected,
  disabled,
}) => {
  const classes = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MuiRadioGroup {...field}>
          <MuiBox className={classes.root}>
            {buttons.map((button, index) => (
              <MuiFormControlLabel
                disabled={disabled}
                onChange={(e) => {
                  field.onChange(e)
                  onRadioButtonSelected()
                }}
                key={index}
                className={classes.label}
                control={
                  <MuiRadio
                    inputProps={{
                      "data-testid": `radio_${name}_${button.value}`,
                    }}
                    className={classes.radio}
                  />
                }
                value={button.value}
                label={button.text}
              />
            ))}
          </MuiBox>
        </MuiRadioGroup>
      )}
    />
  )
}

export default RadioButtons
