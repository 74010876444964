import Joi from "@hapi/joi"
import { add } from "date-fns"

const freeCardValidation = (data) =>
  Joi.object({
    cardNumber: Joi.string()
      .min(4)
      .max(20)
      .alphanum()
      .required()
      .error((errors) =>
        errors.map((err) => {
          err.message = "Vänligen fyll i giltigt kortnummer"
          return err
        })
      ),
    expirationDate: Joi.date()
      .min("now")
      .max(add(new Date(), { years: 1 }))
      .required()
      .error((errors) => {
        const [error] = errors || []

        switch (error.code) {
          case "date.min":
            error.message = "Utgångsdatumet har passerat"
            break
          case "date.max":
            error.message = "Utgångsdatumet kan max vara ett år i framtiden"
            break
          default:
            error.message = "Vänligen fyll i giltigt utgångsdatum"
            break
        }
        return errors
      }),
  }).validate(data, {
    abortEarly: false,
  })

export default freeCardValidation
