import { createAsyncThunk } from "@reduxjs/toolkit"

import { getUser } from "api/getUser"
import { updateUser } from "api/updateUser"
import { imageUpload } from "api/imageUpload"
import { updatePaymentStatus } from "api/updatePaymentStatus"

import { showAlert } from "redux/common"
import { getDateConsideringDaylightSavingTime } from "utils/getDateConsideringDaylightSavingTime"
import { getUserId } from "./selectors"

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async (id) => {
    const response = await getUser(id)
    return {
      ...response.data,
      meetingStart: response.data.meetingStart
        ? getDateConsideringDaylightSavingTime(response.data.meetingStart)
        : null,
    }
  }
)

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (data, thunkApi) => {
    const { userId } = getUserId(thunkApi.getState())

    try {
      await updateUser(userId, data)
      thunkApi.dispatch(showAlert({ message: "Profil sparad" }))

      return data
    } catch (e) {
      thunkApi.dispatch(
        showAlert({
          type: "error",
          message: "Något gick fel, misslyckades att spara",
        })
      )
    }
  }
)

export const updateUserAvatar = createAsyncThunk(
  "user/updateUserAvatar",
  async (file, thunkApi) => {
    const { userId } = getUserId(thunkApi.getState())

    try {
      const { data } = await imageUpload(userId, file)
      thunkApi.dispatch(showAlert({ message: "Avatar sparad" }))

      return data.url
    } catch (e) {
      const errorMessage =
        e?.response?.data || "Något gick fel med bilduppladdningen"

      thunkApi.dispatch(
        showAlert({
          type: "error",
          message: errorMessage,
        })
      )
    }
  }
)

export const setUserCustomerStatusInactive = createAsyncThunk(
  "user/setUserCustomerStatusInactive",
  async (_, thunkApi) => {
    const { userId } = getUserId(thunkApi.getState())

    try {
      await updatePaymentStatus(userId)

      return { customerStatus: "inactive_customer" }
    } catch (e) {
      thunkApi.dispatch(showAlert({ type: "error", message: "Något gick fel" }))
      return thunkApi.rejectWithValue(e)
    }
  }
)
