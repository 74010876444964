export default {
  isDev: process.env.REACT_APP_IS_DEV === "true",
  company_name: "DinPsykolog",
  redirect_url: process.env.REACT_APP_REDIRECT_URL_AUTH,
  api_url: process.env.REACT_APP_REDIRECT_URL_API,
  ws_url: process.env.REACT_APP_REDIRECT_WS_URL,
  cookie_path: process.env.REACT_APP_COOKIE_PATH,
  stripe_pub_key: process.env.REACT_APP_STRIPE_PUB_KEY,
  kaddioBookingUrl: process.env.REACT_APP_KADDIO_BOOKING_URL,
  faq: "https://dinpsykolog.se/vanliga-fragor/",
  support: "https://dinpsykolog.se/support/",
  payment_info_link: "https://dinpsykolog.se/#vanliga-fragor",
  gtmId: "GTM-KJHD87Q",
  slackErrorsTestChannelId: "C033VE8GVPZ",
  slackErrorsProdChannelId: "C034CBSR0BE",
}
