import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  CircularProgress,
  Typography,
  Hidden,
  Button,
  Box,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { getOnboardingStep } from "redux/onboarding/selectors"
import {
  finishOnboarding,
  prevStep as prevFormStep,
  getCaregivers as fetchCaregivers,
  getCaregiversSorted as fetchCaregiversSorted,
} from "redux/onboarding"
import { getUserId } from "redux/user"
import CaregiverCard from "../CaregiverCard"
import FormStepper from "./components/FormStepper"

const useStyles = makeStyles((theme) => ({
  loading: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  container: {
    padding: theme.spacing(3),
    flexGrow: 1,
    overflow: "auto",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  wrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  cardContainer: {
    maxWidth: 400,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    boxSizing: "border-box",
    "&:nth-child(odd)": {
      paddingRight: Number(theme.spacing(3).slice(0, -2)) / 2,
    },
    "&:nth-child(even)": {
      paddingLeft: Number(theme.spacing(3).slice(0, -2)) / 2,
    },
    [theme.breakpoints.down("md")]: {
      "&:nth-child(odd)": {
        paddingRight: 0,
      },
    },
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  header: {
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.125rem",
    },
  },
  text: {
    textAlign: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
  subheader: {
    fontSize: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    marginBottom: theme.spacing(2),
  },
  email: {
    fontWeight: 500,
    display: "inline-block",
  },
  subheaderContainer: {
    marginBottom: theme.spacing(2),
  },
}))

const getArrays = (data) => {
  const even = []
  const odd = []

  data.forEach((item, index) => {
    if (index % 2) {
      odd.push(item)
    } else {
      even.push(item)
    }
  })
  return { odd, even }
}

const CardContainer = ({ caregivers, className, onClick }) => (
  <div className={className}>
    {caregivers.map((caregiver) => (
      <CaregiverCard
        onClick={onClick}
        caregiver={caregiver}
        key={caregiver.userId}
      />
    ))}
  </div>
)

const ChooseCaregiverForm = ({
  getCaregivers,
  getCaregiversSorted,
  completeOnboarding,
  prevStep,
  step,
  caregivers,
  loading,
  error,
  userId,
}) => {
  const classes = useStyles()
  const [isSortingChecked, setIsSortingChecked] = useState(false)

  const selectCaregiver = () => (caregiverId) => {
    completeOnboarding(caregiverId)
  }

  useEffect(
    () =>
      isSortingChecked ? getCaregiversSorted(userId) : getCaregivers(userId),
    [isSortingChecked]
  )

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={50} />
      </div>
    )
  }

  if (error) {
    return (
      <Box className={classes.loading}>
        <ErrorOutlineIcon color="primary" className={classes.icon} />
        <Typography variant="h4">Något gick fel!</Typography>
        <Button
          variant="contained"
          onClick={() => getCaregivers(userId)}
          className={classes.errorButton}
        >
          Försök igen
        </Button>
      </Box>
    )
  }

  if (caregivers) {
    const { even, odd } = getArrays(caregivers)

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography
            component="h1"
            variant="h5"
            display="block"
            className={classes.title}
            data-testid="onboarding_title"
          >
            {step + 1}. Välj psykolog
          </Typography>
          {caregivers.length > 0 && (
            <Box className={classes.subheaderContainer}>
              <Typography variant="body1" className={classes.subheader}>
                Här väljer du din psykolog och startar en chatt med IKBT utan
                tidsbokning eller bokar en tid för videomöte.
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSortingChecked}
                      onClick={() => setIsSortingChecked(!isSortingChecked)}
                    />
                  }
                  label="Sortera enligt nästa tillgängliga videosamtal"
                />
              </FormGroup>
            </Box>
          )}
          <div className={classes.wrap}>
            <Hidden mdUp>
              <CardContainer
                onClick={selectCaregiver()}
                caregivers={caregivers}
                className={classes.cardContainer}
              />
            </Hidden>
            <Hidden mdDown>
              <CardContainer
                onClick={selectCaregiver()}
                caregivers={even}
                className={classes.cardContainer}
              />
              <CardContainer
                onClick={selectCaregiver()}
                caregivers={odd}
                className={classes.cardContainer}
              />
            </Hidden>
          </div>

          {caregivers.length === 0 && (
            <Typography variant="body1">
              Just nu har vi inga lediga psykologer som arbetar med det du söker
              för, maila oss gärna på{" "}
              <Typography className={classes.email}>
                info@dinpsykolog.se
              </Typography>
              {", "}
              så hjälper vi dig.
            </Typography>
          )}
        </div>
        <FormStepper prevClick={prevStep} hideNext />
      </div>
    )
  }

  return null
}

const mapStateToProps = (state) => {
  const step = getOnboardingStep(state)
  const { userId } = getUserId(state)
  const { chooseCaregiver } = state.onboarding
  const { caregivers, loading, error } = chooseCaregiver

  return { userId, caregivers, step, loading, error }
}

const mapDispatchToProps = (dispatch) => ({
  getCaregivers: (userId) => dispatch(fetchCaregivers(userId)),
  getCaregiversSorted: (userId) => dispatch(fetchCaregiversSorted(userId)),
  completeOnboarding: (caregiverId) => dispatch(finishOnboarding(caregiverId)),
  prevStep: () => dispatch(prevFormStep()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCaregiverForm)
