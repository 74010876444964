import Joi from "@hapi/joi"

const freeTextSchema = (data, type) => {
  switch (type) {
    case "email":
      return Joi.object({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .error((errors) =>
            errors.map((err) => {
              err.message = "Vänligen fyll i giltig e-post"
              return err
            })
          ),
      }).validate(data, {
        abortEarly: false,
      })
    default:
      throw new Error("Unknown freeText type")
  }
}

export default freeTextSchema
