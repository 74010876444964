import { createAsyncThunk } from "@reduxjs/toolkit"

import { matchCaregiverAndPatient } from "api/matchCaregiverAndPatient"
import { onboarding } from "api/onboarding"
import { getRecommendedCaregiversSortedByOccupancy } from "api/getRecommendedCaregiversSortedByOccupancy"
import { getRecommendedCaregiversSortedByAvailableTime } from "api/getRecommendedCaregiversSortedByAvailableTime"
import { showAlert } from "redux/common"

export const fetchQuestions = createAsyncThunk(
  "onboarding/fetchQuestions",
  async (userId) => {
    const [{ data: questions }, { data: answers }] = await onboarding(userId)
    questions.push({ type: "serviceInfo" }, { type: "chooseCaregiver" })
    const visitTypeQuestionId = 600
    const sortedAnswers = answers.sort((a, b) => a.questionId - b.questionId)
    const visitTypeVideoAnswerIndex = sortedAnswers.findIndex(
      ({ questionId, answer }) =>
        questionId === visitTypeQuestionId && answer[0] === "video"
    )
    if (visitTypeVideoAnswerIndex !== -1) {
      sortedAnswers.length = visitTypeVideoAnswerIndex
    }
    const filteredAnswers = sortedAnswers.map((obj) => obj.answer)
    const step = filteredAnswers.length

    return { questions, answers: filteredAnswers, step }
  }
)

export const finishOnboarding = createAsyncThunk(
  "onboarding/finishOnboarding",
  async (caregiverId, thunkApi) => {
    try {
      await matchCaregiverAndPatient(caregiverId)
    } catch (e) {
      thunkApi.dispatch(
        showAlert({
          type: "error",
          message: "Något gick fel",
        })
      )
      return thunkApi.rejectWithValue(e)
    }
  }
)

export const getCaregivers = createAsyncThunk(
  "onboarding/getCaregivers",
  async (userId) => {
    const { data: recommended } =
      await getRecommendedCaregiversSortedByOccupancy(userId)

    return recommended
  }
)

export const getCaregiversSorted = createAsyncThunk(
  "onboarding/getCaregiversSorted",
  async (userId) => {
    const { data: recommended } =
      await getRecommendedCaregiversSortedByAvailableTime(userId)

    return recommended
  }
)
