import React from "react"
import { shape, string } from "prop-types"
import SingleAnswerForm from "./SingleAnswerForm"
import MultipleAnswerForm from "./MultipleAnswerForm"
import FreeTextForm from "./FreeTextForm"
import ServiceInfoForm from "./ServiceInfoForm"
import ChooseCaregiverForm from "./ChooseCaregiverForm"

const emailSubTitle =
  "I nästa steg visar vi de psykologer vi rekommenderar för dig."

const FormTypes = ({ formStep }) => {
  const { type } = formStep

  switch (type) {
    case "singleAnswer":
      return (
        <div key={formStep.id}>
          <SingleAnswerForm data={formStep} />
        </div>
      )
    case "multipleAnswer":
      return (
        <div key={formStep.id}>
          <MultipleAnswerForm data={formStep} />
        </div>
      )
    case "freeText":
      return (
        <div key={formStep.id}>
          <FreeTextForm data={{ ...formStep, subTitle: emailSubTitle }} />
        </div>
      )
    case "serviceInfo":
      return (
        <div key={formStep.id}>
          <ServiceInfoForm />
        </div>
      )
    case "chooseCaregiver":
      return (
        <div key={formStep.id}>
          <ChooseCaregiverForm />
        </div>
      )
    default:
      return null
  }
}

FormTypes.propTypes = {
  formStep: shape({
    type: string.isRequired,
  }),
}

export default FormTypes
