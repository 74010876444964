import { createSlice } from "@reduxjs/toolkit"
import { fetchQuestions, getCaregivers, getCaregiversSorted } from "./actions"

const initialState = {
  loading: false,
  error: false,
  step: 0,
  questions: [],
  answeredQuestions: [],
  chooseCaregiver: {
    caregivers: null,
    error: false,
    loading: false,
  },
}

const sliceName = "onboarding"

const onboardingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    nextStep: (state, action) => {
      const nextFormStep = state.step + 1
      const formLength = state.questions.length

      if (action.payload !== undefined) {
        state.answeredQuestions[state.step] = Object.values(action.payload)
      }

      if (nextFormStep < formLength) {
        state.step = nextFormStep
      }
    },
    prevStep: (state) => {
      const prevStep = state.step - 1

      if (prevStep >= 0) {
        state.step = prevStep
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        Object.assign(state, { error: false, loading: true })
      })
      .addCase(fetchQuestions.rejected, (state) => {
        Object.assign(state, { error: true, loading: false })
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        const { questions, answers, step } = action.payload

        Object.assign(state, {
          questions,
          answeredQuestions: answers,
          step,
          error: false,
          loading: false,
        })
      })
      .addCase(getCaregivers.pending, (state) => {
        Object.assign(state, {
          chooseCaregiver: { caregivers: null, error: false, loading: true },
        })
      })
      .addCase(getCaregivers.rejected, (state) => {
        Object.assign(state, {
          chooseCaregiver: { caregivers: null, error: true, loading: false },
        })
      })
      .addCase(getCaregivers.fulfilled, (state, action) => {
        const chooseCaregiver = {
          caregivers: action.payload,
          error: false,
          loading: false,
        }

        Object.assign(state, { chooseCaregiver })
      })
      .addCase(getCaregiversSorted.pending, (state) => {
        Object.assign(state, {
          chooseCaregiver: { caregivers: null, error: false, loading: true },
        })
      })
      .addCase(getCaregiversSorted.rejected, (state) => {
        Object.assign(state, {
          chooseCaregiver: { caregivers: null, error: true, loading: false },
        })
      })
      .addCase(getCaregiversSorted.fulfilled, (state, action) => {
        const chooseCaregiver = {
          caregivers: action.payload,
          error: false,
          loading: false,
        }

        Object.assign(state, { chooseCaregiver })
      })
  },
})

export default onboardingSlice
