import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import io from "socket.io-client"
import { Box, Hidden, Typography, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Sidebar from "components/Sidebar"
import ChatBar from "pages/chat/components/ChatBar"
import ChatContainer from "pages/chat/components/ChatContainer"
import {
  fetchUserChats as getUserChats,
  fetchUserChatSession as getUserChatSession,
  postMessageInChat as postMessage,
} from "redux/chat"
import { fetchCaregiverAbsence as getCaregiverAbsence } from "redux/caregiver"
import { chatMessagesSeen } from "api/chatMessagesSeen"
import config from "config"
import ChatLoading from "pages/chat/components/ChatLoading"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import PaymentModal from "components/PaymentModal"
import FrikortModal from "components/FrikortModal"
import sessionHandler from "utils/sessionHandler"
import { selectCommonState } from "redux/common/selectors"
import ContinueSession from "components/ContinueSession"
import { differenceInCalendarDays, isAfter } from "date-fns"
import ChatBanner from "./components/ChatBanner"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  content: {
    boxSizing: "border-box",
    flex: 4,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      borderRight: `0.5px solid ${theme.palette.gray}`,
    },
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  header: {
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.125rem",
    },
  },
  text: {
    textAlign: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
}))

const isFirstPatientMessage = (messages, patientId) =>
  messages.filter((message) => message.fromUserId === patientId).length === 0

const Chat = ({
  fetchUserChatSession,
  fetchCaregiverAbsence,
  fetchUserChats,
  postMessageInChat,
  user,
  chat,
  token,
  caregiver,
}) => {
  const classes = useStyles()
  const { loading, error, messages, id: activeChatId, session } = chat
  const {
    userId,
    freeCard,
    customerStatus,
    latestPayment,
    meetingStart: videoMeetingStart,
    meetingUrl: videoMeetingUrl,
  } = user
  const { data, absence } = caregiver
  const socket = io(`${config.ws_url}?token=${token}`)
  const [isActiveSession, setIsActiveSession] = useState(false)
  const [isPaymentOpen, setPaymentOpen] = useState(false)
  const [isFrikortOpen, setFrikortOpen] = useState(false)
  const [isSessionQuestionOpen, setSessionQuestionOpen] = useState(false)
  const [isChatLoaded, setChatLoaded] = useState(false)
  const [isBannerOpen, setBannerOpen] = useState(false)
  const [daysUntilAbsence, setDaysUntilAbsence] = useState(null)
  const [absenceDuration, setAbsenceDuration] = useState(null)

  useEffect(() => {
    const loadData = async () => {
      if (userId) {
        await fetchUserChatSession({ userId, limit: 1 })
        await fetchUserChats(userId)
        if (data?.id) {
          await fetchCaregiverAbsence({
            caregiverId: data?.id,
            excludeEnded: true,
          })
        }
      }
      setChatLoaded(true)
    }
    loadData()
  }, [userId, data.id])

  useEffect(() => {
    const absenceHandler = () => {
      const calculateDateDifference = (startDate, endDate) =>
        differenceInCalendarDays(new Date(startDate), new Date(endDate))
      const isSessionEndingAfterAbsence = (sessionEndDate, absenceStartDate) =>
        isAfter(new Date(sessionEndDate), new Date(absenceStartDate))
      if (absence) {
        setDaysUntilAbsence(
          calculateDateDifference(absence.startAt, new Date())
        )
        setAbsenceDuration(
          calculateDateDifference(
            new Date(absence.endAt),
            new Date(absence.startAt)
          )
        )
        setBannerOpen(
          (session &&
            isSessionEndingAfterAbsence(session.endedAt, absence.startAt) &&
            daysUntilAbsence <= 3) ||
            (!isActiveSession && daysUntilAbsence <= 7)
        )
      }
    }
    absenceHandler()
  }, [absence, daysUntilAbsence, isActiveSession, session])

  useEffect(() => {
    if (isChatLoaded) {
      const isSameCaregiver = (sessionCaregiverId, currentCaregiverId) =>
        sessionCaregiverId === currentCaregiverId
      const hasPreviousSession = (sessionNumber) => sessionNumber >= 1
      const dialogHandler = () => {
        if (isActiveSession) {
          setPaymentOpen(false)
          setSessionQuestionOpen(false)
        } else if (
          hasPreviousSession(session?.sessionNumber) &&
          isSameCaregiver(session?.caregiverId, data.id)
        ) {
          setSessionQuestionOpen(true)
        }
      }
      dialogHandler()
    }
  }, [isChatLoaded, isActiveSession])

  useEffect(() => {
    if (activeChatId) {
      socket.on("connect", () => {
        socket.emit("ready for data", {})
        socket.emit("messageSeen", { chatId: activeChatId, userId })
      })

      socket.on("update", async ({ message }) => {
        if (message.chatId === activeChatId) {
          await postMessageInChat(message)
          await chatMessagesSeen(message.chatId, userId)
          socket.emit("messageSeen", { chatId: message.chatId, userId })
        }
      })

      const handlePaymentModal = async () => {
        const hasSession = await sessionHandler(false)
        setIsActiveSession(hasSession)
      }
      handlePaymentModal()
    }
    return () => {
      socket.disconnect()
    }
  }, [activeChatId, freeCard, customerStatus, latestPayment])

  if (error) {
    return (
      <Box className={classes.loading}>
        <ErrorOutlineIcon color="primary" className={classes.icon} />
        <Typography variant="h4">Något gick fel!</Typography>
        <Button
          variant="contained"
          onClick={() => fetchUserChats(userId)}
          className={classes.errorButton}
        >
          Försök igen
        </Button>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Hidden mdDown>
        <Sidebar />
      </Hidden>
      {loading ? (
        <ChatLoading />
      ) : (
        <Box className={classes.content}>
          {isBannerOpen && (
            <ChatBanner
              caregiver={data}
              absence={absence}
              daysUntilAbsence={daysUntilAbsence}
              absenceDuration={absenceDuration}
            />
          )}
          <ChatContainer
            isSessionQuestionOpen={isSessionQuestionOpen}
            showPaymentModal={setPaymentOpen}
          />
          {!isActiveSession && isChatLoaded && (
            <ContinueSession
              session={session}
              showPaymentModal={setPaymentOpen}
              videoMeetingStart={videoMeetingStart}
              videoMeetingUrl={videoMeetingUrl}
            />
          )}
          {isActiveSession && isChatLoaded && (
            <ChatBar
              showPaymentModal={setPaymentOpen}
              firstPatientMessage={isFirstPatientMessage(messages, userId)}
            />
          )}
          {!isActiveSession && isPaymentOpen && (
            <PaymentModal
              open={isPaymentOpen}
              absence={absence}
              daysUntilAbsence={daysUntilAbsence}
              absenceDuration={absenceDuration}
              close={() => {
                setPaymentOpen(false)
              }}
              onPaymentDecision={() => {
                setPaymentOpen(false)
                setFrikortOpen(true)
              }}
            />
          )}
          <FrikortModal
            open={isFrikortOpen}
            close={() => setFrikortOpen(false)}
            goBack={() => {
              setFrikortOpen(false)
              setPaymentOpen(true)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { token } = selectCommonState(state)
  const { user, caregiver, chat, absence } = state

  return { chat, user, token, caregiver, absence }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserChatSession: (data) => dispatch(getUserChatSession(data)),
  fetchCaregiverAbsence: (caregiverId) =>
    dispatch(getCaregiverAbsence(caregiverId)),
  fetchUserChats: (userId) => dispatch(getUserChats(userId)),
  postMessageInChat: (message) => dispatch(postMessage(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
