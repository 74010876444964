import React from "react"
import { arrayOf, shape, string } from "prop-types"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { showAlert as displayAlert } from "redux/common"
import {
  nextStep as nextFormStep,
  prevStep as prevFormStep,
} from "redux/onboarding"
import { Typography, FormHelperText } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { answerOnboardingQuestion } from "api/answerOnboardingQuestion"
import { difference } from "utils/compareObject"
import isEmpty from "lodash.isempty"
import { translateExpertise } from "utils/translateExpertise"
import { getOnboarding } from "redux/onboarding/selectors"
import Checkbox from "./components/Checkbox"
import FormStepper from "./components/FormStepper"
import multipleAnswerSchema from "../../../../validation/multipleAnswersValidation"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  formContainer: {
    padding: theme.spacing(3),
    flexGrow: 1,
    overflow: "auto",
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
  },
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const resolver = (data) => {
  const { error, value: values } = multipleAnswerSchema(data)
  const responseValues = error ? {} : values
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          multipleAnswer: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const MultipleAnswerForm = ({
  showAlert,
  nextStep,
  prevStep,
  data,
  step,
  answeredQuestions,
  userId,
}) => {
  const classes = useStyles()
  const { question = "", answers = [], id: questionId } = data

  const getDefaultValues = () => {
    const values = {}
    const answered = answeredQuestions[step] || []
    answers.forEach((answer) => {
      values[answer.key] = !!answered.includes(answer.key)
    })
    return values
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    reValidateMode: "onSubmit",
    defaultValues: getDefaultValues(),
  })

  const onSubmit = (formData) =>
    new Promise(() => {
      const answer = []
      const options = Object.keys(formData)
      options.forEach((option) => {
        if (formData[option]) {
          answer.push(option)
        }
      })

      const answerDiff = difference(answer, answeredQuestions[step] || [])
      const answerChanged = !isEmpty(answerDiff)

      if (answerChanged) {
        answerOnboardingQuestion({ answer, userId, questionId })
          .then((response) => {
            nextStep(answer)
          })
          .catch((error) => {
            showAlert({ type: "error", message: "Något gick fel" })
          })
      } else {
        nextStep()
      }
    })

  function prevQuestion() {
    prevStep()
  }

  const renderCheckboxes = () =>
    answers.map((answer, index) => (
      <Checkbox
        key={index}
        name={answer.key}
        label={translateExpertise(answer.key) || answer.text}
        control={control}
      />
    ))

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <div className={classes.formContainer}>
        <Typography
          component="h1"
          variant="h5"
          display="block"
          className={classes.title}
          data-testid="onboarding_title"
        >
          {step + 1}. {question}
        </Typography>
        {!!errors?.multipleAnswer && (
          <FormHelperText className={classes.error}>
            {errors.multipleAnswer.message}
          </FormHelperText>
        )}
        <div className={classes.boxContainer}>{renderCheckboxes()}</div>
      </div>
      <FormStepper disabled={isSubmitting} prevClick={prevQuestion} />
    </form>
  )
}

const mapStateToProps = (state) => getOnboarding(state)

const mapDispatchToProps = {
  showAlert: displayAlert,
  nextStep: nextFormStep,
  prevStep: prevFormStep,
}

MultipleAnswerForm.propTypes = {
  data: shape({
    question: string.isRequired,
    answers: arrayOf(
      shape({
        key: string.isRequired,
        type: string,
        text: string.isRequired,
      })
    ),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleAnswerForm)
