const translations = {
  anxiety: "Ångest",
  stress: "Stress",
  melancholy: "Nedstämdhet",
  depression: "Depression",
  sadness: "Sorg",
  insomnia: "Sömn",
  worry: "Oro",
  phobia: "Fobier",
  compulsion: "Tvång",
  panic_disorder: "Panikångest",
  social_phobia: "Social fobi",
  other: "Annat",
  unsure: "Osäker",
}

export const translateExpertise = (key) => translations[key]
