import React from "react"
import { Avatar } from "@mui/material"
import { makeStyles } from "@mui/styles"
import PersonIcon from "@mui/icons-material/Person"
import classNames from "classnames"

const useStyles = makeStyles((theme) => ({
  avatar: (props) => ({
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: props.size,
    height: props.size,
    borderWidth: 1.5,
    borderColor: theme.palette.lightest_gray,
    textTransform: "uppercase",
    fontSize: `calc(${props.size}px / 2.875)`,
    fontWeight: theme.typography.fontWeightBold,
    "& svg": {
      width: (props.size * 3) / 5,
      height: (props.size * 3) / 5,
    },
  }),
  gray: {
    backgroundColor: `${theme.palette.gray} !important`,
    color: `${theme.palette.dark_gray} !important`,
  },
  secondary: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.light_gray} !important`,
  },
  dark: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
    color: `${theme.palette.light_gray} !important`,
  },
}))

const createInitials = (str) => {
  const allNames = str.trim().split(" ")
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      // eslint-disable-next-line
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, "")
  return initials
}

const DefaultAvatar = ({
  size = 40,
  src = "",
  name,
  secondary,
  dark,
  gray,
}) => {
  const classes = useStyles({ size })

  return (
    <Avatar
      src={src}
      className={classNames(classes.avatar, {
        [classes.dark]: dark,
        [classes.secondary]: secondary,
        [classes.gray]: gray,
      })}
    >
      {name ? createInitials(name) : <PersonIcon />}
    </Avatar>
  )
}

export default DefaultAvatar
