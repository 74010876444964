import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  unmatchButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  errorMessage: {
    color: theme.palette.error.main,
    padding: theme.spacing(1, 3),
    "& p": {
      float: "right",
      marginBottom: theme.spacing(1),
    },
  },
}))

const UnmatchCaregiverConfirmationDialog = ({
  onClose,
  openDialog,
  onUnmatch,
  error,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={openDialog} onClose={onClose} maxWidth="xs">
      <DialogTitle>Byt psykolog</DialogTitle>
      <DialogContent>
        Detta tar dig tillbaka till sidan där du väljer psykolog. Är du säker?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Avbryt
        </Button>
        <Button onClick={onUnmatch} autoFocus className={classes.unmatchButton}>
          OK
        </Button>
      </DialogActions>
      {error && (
        <Box className={classes.errorMessage}>
          <Typography variant="body2">{error}</Typography>
        </Box>
      )}
    </Dialog>
  )
}

export default UnmatchCaregiverConfirmationDialog
