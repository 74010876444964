import React, { useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Logo } from "assets"

const defaultTitle = "Tack för att du överväger att använda Din Psykolog!"

const useStyles = makeStyles((theme) => ({
  infoText: {
    fontStyle: "italic",
  },
  warningText: {
    fontWeight: "bold",
  },
  actionSection: {
    margin: 0,
    padding: theme.spacing(2),
    width: "50%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      width: "100%",
      maxWidth: "8rem",
      marginTop: theme.spacing(1),
    },
  },
}))

const PausedOnboarding = ({
  title = defaultTitle,
  allowContinue,
  exitReason,
  confirmTextData,
  onCloseAction,
  onAnswerConfirmed,
}) => {
  const styles = useStyles()
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
    onCloseAction()
  }

  const handleConfirmation = () => {
    setOpen(false)
    onCloseAction()
    onAnswerConfirmed()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      data-testid="pause_onboarding_dialog"
    >
      <DialogTitle>
        <Logo />
        <Typography gutterBottom>{title}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        {allowContinue && (
          <>
            <Typography gutterBottom>{confirmTextData.text}</Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={styles.infoText}
            >
              *{confirmTextData.info}
            </Typography>
            <Typography gutterBottom className={styles.warningText}>
              {confirmTextData.warning}
            </Typography>
          </>
        )}
        {!allowContinue &&
          (typeof exitReason === "string" ? (
            <Typography gutterBottom> {exitReason} </Typography>
          ) : (
            <>
              <Typography gutterBottom>{exitReason.text}</Typography>
              <Typography variant="body2" gutterBottom>
                {exitReason.info}
              </Typography>
            </>
          ))}
        <Typography gutterBottom />
      </DialogContent>
      <DialogActions>
        <Box className={styles.actionSection}>
          <Button
            variant="text"
            color="default"
            onClick={handleClose}
            className={styles.button}
            data-testid="pause_onboarding_dialog_back"
          >
            Tillbaka
          </Button>
          {allowContinue && (
            <Button
              variant="contained"
              onClick={handleConfirmation}
              className={styles.button}
              data-testid="pause_onboarding_dialog_confirm"
            >
              Jag förstår
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default PausedOnboarding
