import React from "react"
import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { isSameMonth, format, addBusinessDays } from "date-fns"
import { sv } from "date-fns/locale"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: [theme.palette.warning.main],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    color: "white",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
    },
  },
  absenceText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
    },
  },
  absenceCaption: {
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      fontSize: "0.625rem",
    },
  },
}))

const ChatBanner = ({
  caregiver,
  absence,
  daysUntilAbsence,
  absenceDuration,
}) => {
  const classes = useStyles()
  const dateFormat = "d MMM"

  const getAbsenceHeader = () => {
    let header = `${caregiver.firstName} ${
      daysUntilAbsence <= 0 ? " är ledig " : " kommer vara ledig "
    }`
    if (absenceDuration === 0) {
      header += format(new Date(absence.startAt), dateFormat, { locale: sv })
    } else {
      header += format(
        new Date(absence.startAt),
        isSameMonth(new Date(absence.startAt), new Date(absence.endAt))
          ? "d"
          : dateFormat,
        { locale: sv }
      )
      header += " - "
      header += format(new Date(absence.endAt), dateFormat, { locale: sv })
    }

    header = header.replaceAll(".", "")

    return header
  }

  const getAbsenceCaption = () => {
    let caption = `Kontakten fortsätter som vanligt den 
    ${format(addBusinessDays(new Date(absence.endAt), 1), dateFormat, {
      locale: sv,
    })}. Maila info@dinpsykolog.se om du har några frågor eller vill byta psykolog.`
    caption = caption.replaceAll("..", ".")

    return caption
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.absenceText}>
        {getAbsenceHeader()}
      </Typography>
      <Typography className={classes.absenceCaption} variant="caption">
        {daysUntilAbsence <= 0 && getAbsenceCaption()}
      </Typography>
    </Box>
  )
}

export default ChatBanner
