import React, { useState } from "react"
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material"
import { Box, Button, Typography, Link } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import BookingConfirmationModal from "./BookingConfirmationModal"

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    height: "90%",
    margin: `${theme.spacing(3)} auto`,
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  timeSelector: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  timeslotContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    "& button": {
      "&:hover, &:active, &:focus": {
        background: theme.palette.primary.main,
        color: theme.palette.white,
      },
    },
  },
  link: {
    cursor: "pointer",
    color: theme.palette.dark_gray,
    textDecorationColor: theme.palette.dark_gray,
  },
  timeslotButton: {
    borderRadius: theme.spacing(2),
    width: "30%",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    lineHeight: "2.5",
  },
  timeslotDate: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 2),
    },
  },
}))

const TimeSlot = ({ date, onTimeSlotSelect }) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={classes.timeslotButton}
      onClick={onTimeSlotSelect}
    >
      {format(new Date(date), "HH:mm")}
    </Button>
  )
}

const AvailableVideoTimes = ({
  availabilities,
  caregiverFirstName,
  caregiverLastName,
  openModal,
}) => {
  const [dateIndex, setDateIndex] = useState(0)
  const [isIncrementDisabled, setIsIncrementDisabled] = useState(
    availabilities.length - 1 === 0
  )
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(true)
  const [openBookingConfirmationModal, setOpenBookingConfirmationModal] =
    useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
  const classes = useStyles()

  const incrementDate = () => {
    const newIndex = dateIndex + 1
    setDateIndex(newIndex)
    setIsIncrementDisabled(newIndex === availabilities.length - 1)
    setIsDecrementDisabled(false)
  }

  const decrementDate = () => {
    const newIndex = dateIndex - 1
    setDateIndex(newIndex)
    setIsDecrementDisabled(newIndex === 0)
    setIsIncrementDisabled(false)
  }

  const onTimeSlotSelect = (start, end) => {
    setSelectedTimeSlot({ start, end })
    setOpenBookingConfirmationModal(true)
  }

  return (
    <>
      <Typography
        textAlign="left"
        fontWeight="bold"
      >{`${caregiverFirstName} ${caregiverLastName}`}</Typography>
      <Box className={classes.dataContainer}>
        <Box className={classes.timeSelector}>
          <Button disabled={isDecrementDisabled} onClick={decrementDate}>
            <KeyboardArrowLeftIcon />
          </Button>
          <Typography fontWeight="bold" className={classes.timeslotDate}>
            {format(
              new Date(availabilities[dateIndex]?.dayDate),
              "d MMMM yyyy",
              {
                locale: sv,
              }
            )}
          </Typography>
          <Button disabled={isIncrementDisabled} onClick={incrementDate}>
            <KeyboardArrowRightIcon />
          </Button>
        </Box>
        <Box className={classes.timeslotContainer}>
          {availabilities[dateIndex]?.slots?.map((date, index) => (
            <TimeSlot
              key={index}
              date={date?.start}
              onTimeSlotSelect={() => onTimeSlotSelect(date?.start, date?.end)}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Link href="/" underline="always" className={classes.link}>
          Tillbaka hem
        </Link>
      </Box>
      <BookingConfirmationModal
        openDialog={openBookingConfirmationModal}
        onClose={() => setOpenBookingConfirmationModal(false)}
        onConfirm={() =>
          openModal(selectedTimeSlot?.start, selectedTimeSlot?.end)
        }
        selectedTimeSlot={selectedTimeSlot?.start}
        caregiverFirstName={caregiverFirstName}
      />
    </>
  )
}

export default AvailableVideoTimes
