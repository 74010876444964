import { createSelector } from "@reduxjs/toolkit"
import { selectUserState } from "redux/user"

const selectOnboardingState = (state) => state.onboarding

const getOnboardingStep = createSelector(
  selectOnboardingState,
  (state) => state.step
)

const getOnboarding = createSelector(
  selectOnboardingState,
  selectUserState,
  (onboarding, user) => {
    const { userId, onboardingFinished } = user

    return { ...onboarding, userId, onboardingFinished }
  }
)

export { selectOnboardingState, getOnboarding, getOnboardingStep }
