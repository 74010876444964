import React from "react"
import { connect } from "react-redux"
import { Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DefaultAvatar from "components/DefaultAvatar"

const IMAGE_SIZE = 150

const useStyles = makeStyles((theme) => ({
  caregiverOuterCircle: {
    display: "block",
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: "100%",
  },
  caregiverInnerCircle: {
    display: "block",
    border: "2px solid #fff",
    borderRadius: "100%",
  },
}))

const CaregiverImage = ({ avatarUrl, firstName, lastName }) => {
  const classes = useStyles()

  return (
    <Box className={classes.caregiverOuterCircle}>
      <Box className={classes.caregiverInnerCircle}>
        <DefaultAvatar
          size={IMAGE_SIZE}
          src={avatarUrl}
          name={`${firstName} ${lastName}`}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { caregiver } = state
  const { avatarUrl, firstName, lastName } = caregiver?.data || {}

  return { avatarUrl, firstName, lastName }
}

export default connect(mapStateToProps)(CaregiverImage)
