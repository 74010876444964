import { createSlice } from "@reduxjs/toolkit"

const getAlertType = (type) => {
  switch (type) {
    case "error":
    case "warning":
    case "info":
      return type
    default:
      return "success"
  }
}

const initialState = {
  loading: true,
  alert: null,
  token: null,
}

const sliceName = "common"

const commonSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setToken: (state, action) => {
      Object.assign(state, { token: action.payload })
    },
    setInitialLoading: (state, action) => {
      Object.assign(state, { loading: action.payload })
    },
    showAlert: (state, action) => {
      Object.assign(state, {
        alert: {
          type: getAlertType(action.payload.type),
          message: action.payload.message,
          show: true,
        },
      })
    },
    hideAlert: (state, action) => {
      Object.assign(state, {
        alert: { show: false, type: getAlertType(action.payload.type) },
      })
    },
  },
})

export default commonSlice
