import Joi from "@hapi/joi"

const method = (data, helpers) => {
  const values = Object.values(data)
  const isBool = values.every((value) => typeof value === "boolean")

  if (isBool) {
    const filteredValues = values.filter((value) => value)

    if (filteredValues.length > 0) {
      return data
    }
    return helpers.message("Välj minst ett alternativ")
  }
  return helpers.message("Måste vara en boolean")
}

const multipleAnswerSchema = (data) => {
  const schema = Joi.custom(method, "custom validation")

  return schema.validate(data, {
    abortEarly: false,
  })
}

export default multipleAnswerSchema
