import React from "react"
import { Controller } from "react-hook-form"
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  Box as MuiButton,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { alpha } from "@mui/material/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  checkBox: {
    display: "none",
  },
  label: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > span:last-child": {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: 6,
      paddingBottom: 6,
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
      color: theme.palette.primary.main,
    },
    "& > span:first-child.Mui-checked+span": {
      backgroundColor: theme.palette.primary.main,
      border: "none",
      color: "#fff",
    },
  },
}))

const Checkbox = ({ name = "", label = "", control }) => {
  const classes = useStyles()
  return (
    <MuiButton className={classes.root}>
      <MuiFormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <MuiCheckbox
                checked={field.value}
                inputRef={field.ref}
                onChange={(e) => field.onChange(e.target.checked)}
                onBlur={field.onBlur}
                className={classes.checkBox}
                inputProps={{ "data-testid": `checkbox_${name}` }}
              />
            )}
          />
        }
        label={label}
        labelPlacement="end"
        className={classes.label}
      />
    </MuiButton>
  )
}

export default Checkbox
