import React from "react"
import { connect } from "react-redux"
import { MobileStepper as MuiMobileStepper } from "@mui/material"
import { withStyles } from "@mui/styles"
import { getOnboarding } from "redux/onboarding/selectors"

const StyledMobileStepper = withStyles({
  root: {
    maxWidth: "100%",
    backgroundColor: "transparent",
    padding: 0,
  },
  progress: {
    width: "100%",
    height: 8,
  },
})(MuiMobileStepper)

const FormProgress = ({ step, questions }) => {
  const steps = questions.length

  return (
    <StyledMobileStepper
      activeStep={step}
      position="static"
      steps={steps}
      variant="progress"
    />
  )
}

const mapStateToProps = (state) => getOnboarding(state)

export default connect(mapStateToProps)(FormProgress)
