import {
  Modal,
  Box,
  Typography,
  Link as LinkButton,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { sessionType } from "utils/sessionType"
import { fetchCheckoutSession } from "api/fetchCheckoutSession"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    backgroundColor: theme.palette.lighter_gray,
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflowY: "auto",

    [theme.breakpoints.up("sm")]: {
      maxWidth: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginTop: theme.spacing(1),
    },
  },
  button: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(1),
    textTransform: "none",
    borderRadius: theme.spacing(2.5),
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "&:last-of-type": {
        marginTop: theme.spacing(1),
      },
    },
  },
  freecardLink: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
  },
  title: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonTitle: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
  },
  text: {
    color: theme.palette.black,
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      fontSize: "0.9rem",
    },
  },
  textContainer: {
    margin: theme.spacing(3, 0),
    textAlign: "center",
  },
  captionTitle: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
}))

const VideoPaymentModal = ({
  open,
  close,
  onPaymentDecision,
  selectedVideoCallSlot,
  caregiverId,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { start, end } = selectedVideoCallSlot

  useEffect(() => {
    const popStateAction = () => {
      navigate("/video")
    }
    window.addEventListener("popstate", popStateAction)
    return () => window.removeEventListener("popstate", popStateAction)
  }, [])

  const redirectToCheckout = async () => {
    const {
      data: { url },
    } = await fetchCheckoutSession({
      type: sessionType.VIDEO,
      details: { start, end, caregiverId },
    })
    window.location.href = url
  }

  return (
    <Modal className={classes.root} open={open} onClose={close}>
      <Box className={classes.wrapper}>
        <Typography variant="body1" className={classes.title}>
          Välj betalsätt
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            onClick={() => {
              redirectToCheckout()
            }}
          >
            <Typography variant="h6" className={classes.buttonTitle}>
              BETALA MED KORT
            </Typography>
          </Button>
          <LinkButton
            className={classes.freecardLink}
            component="button"
            variant="body2"
            onClick={() => onPaymentDecision()}
          >
            <Typography variant="subtitle2">Jag har frikort</Typography>
          </LinkButton>
        </Box>
        <Box className={classes.textContainer}>
          <Typography
            variant="h6"
            align="center"
            className={classes.captionTitle}
          >
            Patientavgift 100kr
          </Typography>
          <Typography className={classes.captionSubtitle}>
            När du betalat skickas en länk till videomötet ut till din e-post du
            angivit. Om du behöver avboka ditt möte maila info@dinpsykolog.se.
            Om det är mindre än 24 timmar till ditt möte görs ingen
            återbetalning.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default VideoPaymentModal
