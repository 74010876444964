import React, { useState } from "react"
import { shape, arrayOf, string, number } from "prop-types"
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Avatar,
  Chip,
  Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { translateExpertise } from "utils/translateExpertise"
import { format } from "date-fns"
import { sv } from "date-fns/locale"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  caregiverInfo: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  avatarContainer: {
    padding: theme.spacing(2, 0, 0, 0),
    maxWidth: "45%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  infoContainer: {
    padding: theme.spacing(2, 0, 0, 0),
    marginLeft: theme.spacing(1),
  },
  avatar: {
    position: "relative",
    alignSelf: "center",
    width: 95,
    height: 95,
    border: `2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(1),
  },
  skills: {
    fontWeight: "bold",
    fontSize: "0.875rem",
  },
  button: {
    flex: 1,
    textTransform: "none",
    borderRadius: theme.spacing(2),
    "&:first-child": {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  chip: {
    marginBottom: theme.spacing(2),
    fontSize: "0.625rem",
    "& .MuiChip-root": {
      fontSize: "inherit",
    },
  },
  badgeContainer: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  badge: {
    fontSize: "0.75rem",
    "&:nth-child(even)": {
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 1),
    },
  },
  caregiverTitle: {
    fontSize: "1.25rem",
    fontWeight: "700",
    marginBottom: theme.spacing(1),
  },
  availableTimeContainer: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.lighter_gray}`,
    background: theme.palette.lighter_gray,
    width: "90%",
  },
  availableTimeText: {
    fontSize: "0.75rem",
    fontWeight: 500,
  },
  availableTimeDate: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
  },
  skillsCointainer: {
    padding: theme.spacing(0, 2, 0, 2),
  },
  skillsList: {
    color: theme.palette.default.dark,
    fontSize: "0.875rem",
  },
  descriptionContainer: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  descriptionText: {
    fontSize: "0.875rem",
  },
  seeMoreButton: {
    border: "none",
    outline: "none",
    background: "transparent",
    cursor: "pointer",
    color: theme.palette.dark_gray,
    fontSize: "0.875rem",
    textTransform: "none",
    fontWeight: "bold",
    padding: 0,
    float: "right",
  },
}))

const Skills = ({ expertise }) => {
  const translatedExpertise = expertise.map(
    (exp) => translateExpertise(exp) || exp
  )
  return translatedExpertise.join(" | ")
}

const CaregiverCard = ({ caregiver, onClick }) => {
  const classes = useStyles()
  const {
    userId = "",
    firstName = "",
    lastName = "",
    longDesc = "",
    avatarUrl = "",
    expertise = [],
    slot,
  } = caregiver
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)

  const renderDescription = (text) => {
    if (!isDescriptionExpanded && text.length > 140) {
      return (
        <Typography component="div" className={classes.descriptionText}>
          {text.slice(0, 100)}
          <Button
            onClick={() => setIsDescriptionExpanded(true)}
            type="button"
            className={classes.seeMoreButton}
          >
            ...läs mer
          </Button>
        </Typography>
      )
    }
    return (
      <Typography component="div" className={classes.descriptionText}>
        {text}
      </Typography>
    )
  }

  return (
    <Card raised className={classes.root} data-testid="caregiver_card">
      <Box className={classes.caregiverInfo}>
        <Box className={classes.avatarContainer}>
          <Avatar src={avatarUrl} className={classes.avatar} />

          <Box className={classes.chip}>
            <Chip
              data-testid="caregiver_card_recommended"
              color="primary"
              label="Rekommenderad"
            />
          </Box>
        </Box>
        <Box className={classes.infoContainer}>
          <Typography className={classes.caregiverTitle}>
            {firstName} {lastName}
          </Typography>
          <Box className={classes.badgeContainer}>
            <Chip
              className={classes.badge}
              data-testid="caregiver_card_recommended"
              color="primary"
              label="IKBT via chatt"
            />

            {slot && (
              <Chip
                className={classes.badge}
                data-testid="caregiver_card_recommended"
                color="primary"
                label="Videomöte"
              />
            )}
          </Box>

          {slot && (
            <Box className={classes.availableTimeContainer}>
              <Typography className={classes.availableTimeText}>
                Nästa lediga tid för videomöte
              </Typography>
              <Typography className={classes.availableTimeDate}>
                {format(new Date(slot.start), "eeee d MMMM HH.mm", {
                  locale: sv,
                })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <CardContent className={classes.skillsCointainer}>
        <Typography paragraph>
          <span className={classes.skills}>Behandlingsområden: </span>
          <span className={classes.skillsList}>
            <Skills expertise={expertise} />
          </span>
        </Typography>
      </CardContent>
      <CardContent className={classes.descriptionContainer}>
        {renderDescription(longDesc)}
      </CardContent>
      <CardActions disableSpacing>
        <Button
          variant="contained"
          onClick={() => onClick(userId)}
          className={classes.button}
          data-testid="caregiver_card_pick_button"
        >
          Välj psykolog
        </Button>
      </CardActions>
    </Card>
  )
}

CaregiverCard.propTypes = {
  caregiver: shape({
    userId: string.isRequired,
    firstName: string.isRequired,
    lastName: string.isRequired,
    longDesc: string.isRequired,
    avatarUrl: string,
    expertise: arrayOf(string).isRequired,
    recommendedMatch: number,
  }),
}

export default CaregiverCard
