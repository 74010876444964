import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import format from "date-fns/format"
import sv from "date-fns/locale/sv"

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "1",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const BookingConfirmationModal = ({
  onClose,
  openDialog,
  onConfirm,
  selectedTimeSlot,
  caregiverFirstName,
}) => {
  const classes = useStyles()
  const formattedSelectedTimeSlot = selectedTimeSlot
    ? format(new Date(selectedTimeSlot), "HH.mm", {
        locale: sv,
      })
    : null
  return (
    <Dialog open={openDialog} onClose={onClose}>
      <DialogTitle>Boka tiden</DialogTitle>
      <DialogContent>
        Är du säker på att du vill boka tiden {formattedSelectedTimeSlot} med
        psykolog {caregiverFirstName}?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Avbryt
        </Button>
        <Button onClick={onConfirm} autoFocus className={classes.confirmButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BookingConfirmationModal
