import React from "react"
import moment from "moment"
import { Divider, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  divider: {
    flex: 1,
    backgroundColor: theme.palette.gray,
  },
  text: {
    lineHeight: "15px",
    fontSize: "12px",
    color: theme.palette.dark_gray,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const DateDivider = ({ timeStamp }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Typography className={classes.text} component="span">
        {moment(timeStamp).format("D MMM")}
      </Typography>
      <Divider className={classes.divider} />
    </div>
  )
}

export default DateDivider
